import EmptyList from '@/components/atoms/EmptyList';
import Spinner from '@/components/atoms/Spinner';
import { DataTable } from '@/components/template/DataTable';
import { useEmailMarketing } from '@/hooks/useEmailMarketing';
import routeNames from '@/routes/routeNames';
import { AuthStore } from '@/state/AuthenticationStore';
import {
  EmailCampaignFilter,
  emailMarketingStore,
} from '@/state/EmailMarketingStore';
import { replaceRouteParam } from '@/utils/routes';
import { createColumnHelper } from '@tanstack/react-table';
import { observer } from 'mobx-react-lite';
import { useEffect } from 'react';
import { Link } from 'react-router-dom';
import Stats from './components/Stats';

const columnHelper = createColumnHelper<any>();

const columns = [
  columnHelper.accessor('title', {
    id: 'title',
    cell: info => <p className='py-2'>{info.getValue()}</p>,
    header: () => <span className='text-black'>Title</span>,
  }),
  columnHelper.accessor('template', {
    id: 'template',
    cell: info => <p>{info.getValue()?.name}</p>,
    header: () => <span className='text-black'>Template</span>,
  }),
  columnHelper.accessor('clickCount', {
    id: 'clickCount',
    cell: info => <p>{info.getValue()}</p>,
    header: () => <span className='text-black'>Click Rate</span>,
  }),
  columnHelper.accessor('openCount', {
    id: 'openCount',
    cell: info => <p>{info.getValue()}</p>,
    header: () => <span className='text-black'>Open Rate</span>,
  }),
  columnHelper.accessor('status', {
    id: 'status',
    cell: info => (
      <p className='border border-slate-300 rounded-md px-2 py-1 w-fit text-md'>
        {info.getValue()}
      </p>
    ),
    header: () => <span className='text-black'>Status</span>,
  }),
];

function Analytics() {
  const { getCampaigns, getEmailMarketingStats } = useEmailMarketing();

  useEffect(() => {
    getCampaigns({
      filter: EmailCampaignFilter.ALL,
      limit: 5,
      page: 1,
    });
    getEmailMarketingStats();
  }, []);

  return (
    <div className='p-5 flex flex-col gap-4'>
      <Stats />

      <div className='flex justify-center items-center'></div>
      <div className='w-full bg-white rounded  p-4 '>
        <div className='flex justify-between items-center mb-5 border-border'>
          <div>
            <h2 className='font-bold text-lg'>Campaigns</h2>
            <p className='text-gray-375'>
              See all campaigns and their status in one place
            </p>
          </div>
          <Link
            to={replaceRouteParam(
              routeNames.dashboard.emailMarketing + '/campaigns',
              'workspaceId',
              AuthStore?.user_workspace_info?.active_workspace?.workspace?._id,
            )}
          >
            <small>View all</small>
          </Link>
        </div>
        {emailMarketingStore.loading ? (
          <div className='flex justify-center items-center p-4'>
            <Spinner size={20} />
          </div>
        ) : emailMarketingStore.campaigns?.length > 0 ? (
          <DataTable
            columns={columns}
            data={emailMarketingStore.campaigns}
            headerClassName='bg-gray-100 text-lightBlack font-medium text-md first:w-auto py-2 last:w-auto'
          />
        ) : (
          <EmptyList
            listName={'campaigns'}
            title='There are no'
            subTitle='Add '
            height='40vh'
          />
        )}
      </div>
    </div>
  );
}

export default observer(Analytics);
