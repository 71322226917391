import { notify } from '@/helpers';
import routeNames from '@/routes/routeNames';
import { AuthStore } from '@/state/AuthenticationStore';
import { emailMarketingStore } from '@/state/EmailMarketingStore';
import { replaceRouteParam } from '@/utils/routes';
import React, { useEffect, useRef } from 'react';
import EmailEditor, { EditorRef } from 'react-email-editor';
import { useNavigate, useOutletContext, useParams } from 'react-router-dom';
import { OutletContextType } from '../types';
import { v4 as uuidv4 } from 'uuid';
import { useEmailMarketing } from '@/hooks/useEmailMarketing';

const InFlowEmailBuilder = () => {
  const emailEditorRef = useRef<EditorRef>(null);
  const unlayer = emailEditorRef.current?.editor;
  const { channel } = useParams();
  const { isNextClicked, setIsNextClicked } =
    useOutletContext<OutletContextType>();
  const navigate = useNavigate();
  const { saveInflowTemplate } = useEmailMarketing();

  const goNext = () => {
    navigate(
      replaceRouteParam(
        `${routeNames.dashboard.emailMarketing}/campaign-flow/${channel}/campaign-message`,
        'workspaceId',
        AuthStore.user_workspace_info.active_workspace.workspace._id,
      ),
    );
  };

  useEffect(() => {
    if (isNextClicked) {
      goNext();
      saveDesign();
      setIsNextClicked(false);
    }
  }, [isNextClicked]);

  const saveDesign = () => {
    const randomUuid = uuidv4();
    const randomNumbers = randomUuid.replace(/\D/g, '').slice(0, 6);
    unlayer?.exportHtml(async data => {
      const { design, html } = data;
      const { rows } = design.body;
      let isEmpty = true;
      label: for (let i = 0; i < rows.length; i++) {
        const { columns } = rows[i];
        for (let j = 0; j < columns.length; j++) {
          const { contents } = columns[j];
          if (contents.length) {
            isEmpty = false;
            break label;
          }
        }
      }
      if (isEmpty) {
        notify(
          'error',
          'Please add content to the email template builder before saving',
        );
        return;
      }
      try {
        emailMarketingStore.setLoading(true);
        if (!design || !html) {
          notify('error', 'Invalid design data');
          return;
        }
        const template = {
          html: html,
          name: `Template #${randomNumbers}`,
          type: 'basic',
          json: design,
          status: 'Draft',
        };
        emailMarketingStore.setTemplateOnDemand(template);
        saveInflowTemplate(template);
      } catch (error) {
        notify('error', 'Error: Failed to save template');
      }
    });
  };
  return (
    <div className='flex w-full pb-14 h-full'>
      <EmailEditor ref={emailEditorRef} />
    </div>
  );
};

export default InFlowEmailBuilder;
