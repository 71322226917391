import Button from '@/components/atoms/Button';
import Modal from '@/components/atoms/modal';
import Spinner from '@/components/atoms/Spinner';
import Text from '@/components/atoms/Text';
import { MainContainer } from '@/components/template/MainContainer';
import { convertDateTimeFormat } from '@/helpers/index';
import { useAuth } from '@/hooks/useAuth';
import { useUpload } from '@/hooks/useUpload';
import { AuthStore } from '@/state/AuthenticationStore';
import { contactStore } from '@/state/ContactStore';
import { Icon } from '@iconify/react';
import { observer } from 'mobx-react-lite';
import { useEffect, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import AgentDetails from './AgentDetails';

const AgentProfile = () => {
  const { id } = useParams<{ id: string }>();
  const navigate = useNavigate();
  const [edit, setEdit] = useState(false);
  const [user_name, setName] = useState('');
  const [twitter_followers, setTwitterFollowers] = useState('');
  const [bio, setBio] = useState('');
  const [job_title, setJob] = useState('');
  const profileInputRef = useRef(null);
  const [updatedUser, setUpdatedUser] = useState(null);
  const [profileModalOpen, setProfileModalOpen] = useState(false);
  const [profileImagePreviewUrl, setProfileImagePreviewUrl] = useState('');
  const [selectedProfileImage, setSelectedProfileImage] = useState(null);
  const { uploading, upload, response } = useUpload();
  const { get_user_info, update_user_info_async, updateUserAvatar } = useAuth();
  const [showRemoveOption, setShowRemoveOption] = useState(false);

  const users_info = AuthStore.users_info;
  const date = new Date(users_info.get(id)?.createdAt);
  const user = users_info.get(AuthStore.currentUser?._id);

  const createdDate =
    date.getDate() + '/' + (date.getMonth() + 1) + '/' + date.getFullYear();
  const nameArray = user_name?.split(' ') || [];
  const firstName = nameArray.length ? nameArray[0] : '';
  const lastName = nameArray[nameArray.length - 1] || '';

  const user_details = [
    {
      id: 1,
      name: 'created at',
      value: !user ? '' : createdDate,
      icon: <Icon icon='uit:calender' width={15} height={15} color='#898D94' />,
      visible: true,
    },
    {
      id: 2,
      name: 'email',
      value: !user ? '' : user?.email === null ? '-' : user?.email,
      icon: (
        <Icon icon='bi:mailbox2-flag' width={15} height={15} color='#898D94' />
      ),
      visible: true,
    },
  ];
  const handleEdit = () => {
    if (!edit) {
      return;
    }
    setEdit(!edit);
  };

  const handleUpdateDetails = (args: any) => {
    const update = {
      id: id,
      user: {
        user_name,
        job_title,
        bio,
        twitter_followers,
        ...args,
      },
    };
    update_user_info_async(update);
    handleEdit();
  };

  const openProfileModal = () => {
    setProfileModalOpen(true);
  };
  const closeProfileModal = () => {
    setProfileModalOpen(false);
    setProfileImagePreviewUrl('');
    setSelectedProfileImage(null);
  };

  const handleProfileImageSelected = (event: any) => {
    const blob = new Blob([event.target.files[0]]);
    const blobURL = URL.createObjectURL(blob);
    setProfileImagePreviewUrl(blobURL);
    setSelectedProfileImage(event.target.files[0]);
    openProfileModal();
    event.target.value = null;
  };

  const handleProfileImageUpload = () => {
    upload(selectedProfileImage);
  };

  const removeProfile = () => {
    updateUserAvatar(AuthStore?.currentUser?._id as string, null);
    setShowRemoveOption(false);
  };

  useEffect(() => {
    if (response) {
      if (response.status === 200) {
        updateUserAvatar(
          AuthStore?.currentUser?._id as string,
          response.payload.url,
        );
        closeProfileModal();
      }
    }
  }, [response]);

  useEffect(() => {
    if (id) {
      setUpdatedUser(updatedUser);
      setName(user?.user_name || '');
      setBio(user?.bio || '');
      setJob(user?.job_title || '');
      setTwitterFollowers(user?.twitter_followers || '');
    }
  }, [user]);

  useEffect(() => {
    if (id && !users_info.has(id)) {
      get_user_info(id);
    }
  }, [id]);

  if (!user) {
    return (
      <div className='h-screen  flex justify-center items-center'>
        <Spinner size={40} />
      </div>
    );
  }

  return (
    <>
      <Modal
        openModal={openProfileModal}
        closeModal={closeProfileModal}
        show={profileModalOpen}
        title='Update profile photo'
        className='z-[10000] inline-block py-6 my-8 w-[100%] max-w-[500px]
         overflow-hidden text-left align-top  transition-all transform bg-white
          shadow-xl rounded-[7px]'
      >
        <img
          ref={profileInputRef}
          src={profileImagePreviewUrl}
          className='w-full aspect-auto mt-5'
        />
        <div className='pt-4 px-4 flex flex-row-reverse'>
          <Button
            onClick={handleProfileImageUpload}
            disabled={uploading}
            type='submit'
            size='sm'
            text={'Save photo'}
            className='text-white hover:bg-primary-medium'
          />
          <Button
            onClick={closeProfileModal}
            disabled={uploading}
            size='sm'
            type='button'
            text={'Cancel'}
            className='mr-[15px] bg-[#f1f1f1] hover:bg-grey-light text-[#222]'
          />
        </div>
      </Modal>
      <MainContainer>
        {contactStore.fetchUser ? (
          <div className='py-10 flex justify-center items-center'>
            <Spinner color='blue' size={50} />
          </div>
        ) : (
          <>
            <div className='py-[20px] px-[25px] flex justify-between items-center sticky top-0 bg-white z-[99999]  shadow-sm'>
              <div
                className='flex flex-col sm:flex-row space-x-[24px] pb-[15px] pt-[20px]'
                onMouseLeave={() => setShowRemoveOption(false)}
              >
                <div
                  onClick={() => navigate(-1)}
                  className='flex-none w-[34px] h-[34px]  cursor-pointer rounded-[4px] flex justify-center items-center bg-[#FBFCFD] border border-border'
                >
                  <Icon
                    icon='ph:arrow-left-light'
                    width={18}
                    height={18}
                    className='text-black'
                  />
                </div>
                <div className='flex items-center w-full'>
                  <div
                    className='mx-6 relative group'
                    onMouseOver={() => setShowRemoveOption(true)}
                  >
                    <input
                      type='file'
                      onChange={handleProfileImageSelected}
                      className='opacity-0 cursor-pointer absolute top-0 left-0 right-0 bottom-0 z-10'
                      disabled={user?.profile_picture_url ? true : false}
                    />
                    <span className='2xl:w-20 2xl:h-20 xl:w-16 xl:h-16 w-20 h-20 border-2  group-hover:border-primary transition-colors bg-gray-200 rounded-full inline-block relative'>
                      {user?.profile_picture_url ? (
                        <img
                          src={user?.profile_picture_url}
                          className='h-full w-full rounded-full '
                        />
                      ) : (
                        <div
                          className={`rounded-full h-full w-full bg-secondary text-white flex items-center justify-center font-semibold text-[20px]`}
                        >
                          {firstName.charAt(0).toUpperCase() +
                            lastName.charAt(0).toUpperCase()}
                        </div>
                      )}
                      <span
                        className='flex items-center group-hover:bg-candy_yellow 
                                    group-hover:border-candy_yellow justify-center absolute bottom-0 right-0
                                    2xl:h-8 2xl:w-8 xl:h-6 xl:w-6 w-8 h-8 border border-gray-400 rounded-full bg-white'
                      >
                        <Icon
                          icon='iconamoon:edit-thin'
                          width={18}
                          height={18}
                          className='text-black text-gray-400'
                        />
                      </span>
                    </span>

                    {showRemoveOption && user?.profile_picture_url && (
                      <div
                        onClick={removeProfile}
                        className='absolute bottom-[-30px] z-50 shadow-md right-0 bg-white text-gray-800 cursor-pointer
                                      text-[12px] px-3 py-2 rounded-md hover:bg-gray-25'
                      >
                        Remove
                      </div>
                    )}
                  </div>
                  <div className='flex ml-4 flex-col gap-2'>
                    <Text
                      size='lg'
                      color='text-lightBlack'
                      className='font-medium'
                    >
                      {user?.user_name || ''}
                    </Text>
                    <div className='sm:flex hidden items-center relative left-[-5px]'>
                      <div className='flex gap-1 flex-[.6]'>
                        <div>
                          <Icon
                            icon='carbon:user-identification'
                            width={20}
                            height={20}
                            color='#898D94'
                          />
                        </div>
                        <Text
                          size='sm'
                          color='text-gray-400'
                          className='font-medium'
                        >
                          {user?.type === 'AGENT' ? 'You' : user?.type}
                        </Text>
                      </div>
                      <div className='w-px h-5 border border-border bg-neutral-200 mx-4' />
                      <div className='flex items-center gap-2'>
                        <div>
                          <Icon
                            icon='mdi:clock'
                            width={18}
                            height={18}
                            color='#898D94'
                          />
                        </div>
                        <Text
                          size='sm'
                          color='text-gray-400'
                          className='font-medium'
                        >
                          {convertDateTimeFormat(
                            user?.last_seen === null ? '-' : user?.last_seen,
                          )}
                        </Text>
                      </div>
                    </div>
                  </div>
                </div>
                <div className='flex mt-2 sm:hidden items-center relative'>
                  <div className='flex gap-1 flex-[.6]'>
                    <div>
                      <Icon
                        icon='carbon:user-identification'
                        width={20}
                        height={20}
                        color='#898D94'
                      />
                    </div>
                    <Text
                      size='sm'
                      color='text-gray-400'
                      className='font-medium'
                    >
                      {user?.type === 'AGENT' ? 'You' : user?.type}
                    </Text>
                  </div>
                  <div className='w-px h-5 border border-border bg-neutral-200 mx-4' />
                  <div className='flex items-center gap-2'>
                    <div>
                      <Icon
                        icon='mdi:clock'
                        width={18}
                        height={18}
                        color='#898D94'
                      />
                    </div>
                    <Text
                      size='sm'
                      color='text-gray-400'
                      className='font-medium'
                    >
                      {convertDateTimeFormat(
                        user?.last_seen === null ? '-' : user?.last_seen,
                      )}
                    </Text>
                  </div>
                </div>
              </div>
              <div className='flex space-x-[24px] items-center'></div>
            </div>
            <hr className='h-px border-0 bg-border'></hr>
            <div className='flex '>
              <div className='flex flex-col gap-y-4 border border-l-0 border-y-0 border-border p-[25px] w-full sm:w-[30%]'>
                <AgentDetails data={user} />
                <hr className='h-px border-0 bg-border'></hr>
                <div className='w-[400px] my-4 px-2'>
                  <div className='flex pt-2'>
                    <Text size='md' color='text-black'>
                      Your account
                    </Text>
                  </div>
                  <div className='flex flex-col mt-3 3xl:mt-4'>
                    {user_details.map(item => (
                      <div
                        className='flex flex-row mb-2 items-center truncate justify-between'
                        key={item.id}
                      >
                        <div className='flex flex-row truncate'>
                          {item.icon}
                          <p
                            className={`text-gray-500 font-semibold text-[11px] 3xl:text-[13.5px] 2xl:text-[12px] ml-2`}
                          >
                            {item.name}
                          </p>
                          <p className='text-black font-medium text-[11px] 3xl:text-[13.5px] 2xl:text-[12px] first-line:text-xs ml-3 truncate'>
                            {item.value}
                          </p>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
                <hr className='h-px border-0 bg-border'></hr>
              </div>
              <div className='px-[25px] pt-[25px] rounded-br-[16px] w-[70%]'></div>
            </div>{' '}
          </>
        )}
      </MainContainer>
    </>
  );
};
export default observer(AgentProfile);
