import React from 'react';
import { Outlet, useOutletContext } from 'react-router-dom';
import { OutletContextType } from '../types';

const CampaignMessageFlow = () => {
  const { isNextClicked, setIsNextClicked } =
    useOutletContext<OutletContextType>();
  return <Outlet context={{ isNextClicked, setIsNextClicked }} />;
};

export default CampaignMessageFlow;
