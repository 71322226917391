import MainChatUi from '@/components/template/MainChatUi';

const SMS = () => {
  return (
    <>
      <MainChatUi conversationChannel='SMS' title='SMS' />
    </>
  );
};
export default SMS;
