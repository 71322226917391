import Spinner from '@/components/atoms/Spinner';
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from '@/components/ui/accordion';
import { Alert, AlertDescription, AlertTitle } from '@/components/ui/alert';
import { Badge } from '@/components/ui/badge';
import { Button } from '@/components/ui/button';
import { Card, CardContent, CardHeader, CardTitle } from '@/components/ui/card';
import { Input } from '@/components/ui/input';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from '@/components/ui/table';
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from '@/components/ui/tooltip';
import { useEmailMarketing } from '@/hooks/useEmailMarketing';
import { emailMarketingStore } from '@/state/EmailMarketingStore';
import { Formik } from 'formik';
import { AlertTriangle, Check, ChevronDown, Copy, Plus, X } from 'lucide-react';
import { observer } from 'mobx-react-lite';
import { useEffect } from 'react';
import * as Yup from 'yup';

type Domain = {
  name: string;
  senderName: string;
  status: 'awaiting-submission' | 'pending' | 'success' | 'failed';
  domainRecords: {
    type: string;
    key: string;
    value: string;
    priority?: string;
  }[];
};

const setupSchema = Yup.object().shape({
  domain: Yup.string()
    .trim()
    .matches(
      /^((?!-))(xn--)?[a-z0-9-]+(?<!-)\.[a-z]{2,}(\.[a-z]{2,})?$/,
      'Enter a valid domain',
    )
    .required('Domain is required'),
  senderName: Yup.string().trim().optional().default('sparkhub'),
});

const Setup = () => {
  const { disconnectDomain, verifyDomain, checkDomain } = useEmailMarketing();
  const domainDetails = emailMarketingStore?.setupResponse;

  const copyDnsSettings = (record: {
    type: string;
    key: string;
    value: string;
    priority?: string;
  }) => {
    const setting = `${record.type} ${record.key} ${record.priority || ''} ${
      record.value
    }`.trim();
    navigator.clipboard.writeText(setting);
  };

  const removeDomain = () => {
    disconnectDomain();
  };

  const getStatusBadge = (status: Domain['status']) => {
    switch (status) {
      case 'pending':
        return (
          <Badge variant='outline' className='bg-yellow-100 text-yellow-800'>
            Pending
          </Badge>
        );
      case 'success':
        return (
          <Badge variant='outline' className='bg-green-100 text-green-800'>
            Verified
          </Badge>
        );
      case 'failed':
        return (
          <Badge variant='outline' className='bg-red-100 text-red-800'>
            Failed
          </Badge>
        );
      case 'awaiting-submission':
        return (
          <Badge variant='outline' className='bg-orange-100 text-orange-800'>
            Awaiting Submission
          </Badge>
        );
    }
  };

  useEffect(() => {
    checkDomain();
  }, []);

  if (emailMarketingStore.loading && !emailMarketingStore.showSpinner) {
    return (
      <div className='h-full bg-white flex justify-center items-center'>
        <Spinner size={50} />
      </div>
    );
  }

  return (
    <div className='container mx-auto p-4 space-y-6 overflow-y-scroll'>
      <h1 className='text-2xl font-bold !text-secondary'>Email Setup</h1>

      <Card>
        <CardHeader>
          <CardTitle>Add New Domain</CardTitle>
        </CardHeader>
        <CardContent>
          <Formik
            initialValues={{
              domain: '',
              senderName: '',
            }}
            onSubmit={verifyDomain}
            validationSchema={setupSchema}
          >
            {({ values, handleBlur, handleChange, handleSubmit }) => {
              return (
                <form onSubmit={handleSubmit} id='add_domain'>
                  <div className='flex flex-col sm:flex-row gap-4'>
                    <Input
                      placeholder='Enter domain'
                      name='domain'
                      onChange={handleChange}
                      onBlur={handleBlur}
                      disabled={!!domainDetails?.domain}
                      value={values.domain}
                      className='focus-visible:outline-none focus-visible:ring-0 focus-visible:ring-ring focus-visible:ring-offset-0 focus-within:border focus-within:border-secondary'
                    />
                    <Button
                      className='!bg-secondary !hover:bg-secondary/50 text-white whitespace-nowrap disabled:cursor-not-allowed'
                      type='submit'
                      disabled={!!domainDetails?.domain}
                    >
                      <Plus className='mr-2 h-4 w-4 text-white' /> Add Domain
                    </Button>
                  </div>
                </form>
              );
            }}
          </Formik>
        </CardContent>
      </Card>

      {!domainDetails?.domain ? (
        <>
          <Card>
            <CardHeader>
              <CardTitle>Instructions</CardTitle>
            </CardHeader>
            <CardContent>
              <Alert>
                <AlertTitle className='text-md'>Add Domain</AlertTitle>
                <AlertDescription className='text-md'>
                  Add a domain to send emails from. You will need to verify the
                  domain by adding the DNS records provided to your domain
                </AlertDescription>
              </Alert>
            </CardContent>
          </Card>
        </>
      ) : (
        <Accordion type='single' collapsible className='space-y-4 rounded-lg'>
          <AccordionItem
            value={domainDetails?.domain}
            className='border rounded-lg bg-white'
          >
            <AccordionTrigger className='hover:no-underline px-4 py-2'>
              <div className='flex justify-between items-center w-full'>
                <div className='flex items-center space-x-2'>
                  <h2 className='text-xl font-semibold text-secondary'>
                    {domainDetails?.domain}
                  </h2>
                  {getStatusBadge(domainDetails?.status as Domain['status'])}
                </div>
                <div className='flex items-center space-x-2'>
                  <span className='text-md text-gray-500'>
                    {domainDetails?.domainRecords?.length} DNS records
                  </span>
                  <ChevronDown className='h-4 w-4 !text-secondary transition-transform ui-open:rotate-180' />
                  <Button
                    onClick={e => {
                      e.stopPropagation();
                      removeDomain();
                    }}
                    variant='ghost'
                    size='sm'
                  >
                    <X className='h-4 w-4' />
                  </Button>
                </div>
              </div>
            </AccordionTrigger>
            <AccordionContent>
              <div className='space-y-4 p-4'>
                {domainDetails?.status === 'pending' && (
                  <Alert>
                    <AlertTriangle className='h-4 w-4' />
                    <AlertTitle className='text-md'>
                      Waiting for DNS verification
                    </AlertTitle>
                    <AlertDescription className='text-md'>
                      Please add the following records to{' '}
                      {domainDetails?.domain} to verify. This may take up to 15
                      minutes to register.
                    </AlertDescription>
                  </Alert>
                )}
                {domainDetails.status === 'verified' && (
                  <Alert>
                    <Check className='h-4 w-4' />
                    <AlertTitle className='text-md'>Domain Verified</AlertTitle>
                    <AlertDescription className='text-md'>
                      {domainDetails.domain} has been successfully verified.
                    </AlertDescription>
                  </Alert>
                )}
                {domainDetails.status === 'failed' && (
                  <Alert variant='destructive'>
                    <AlertTriangle className='h-4 w-4' />
                    <AlertTitle className='text-md'>
                      Verification Failed
                    </AlertTitle>
                    <AlertDescription className='text-md'>
                      Unable to verify {domainDetails.domain}. Please check your
                      DNS settings.
                    </AlertDescription>
                  </Alert>
                )}

                <div className='overflow-x-auto'>
                  <Table>
                    <TableHeader>
                      <TableRow>
                        <TableHead className='w-[100px] text-md'>
                          Type
                        </TableHead>
                        <TableHead className='text-md'>Key</TableHead>
                        <TableHead className='w-[100px] text-md'>
                          Priority
                        </TableHead>
                        <TableHead className='text-md'>Value</TableHead>
                        <TableHead className='w-[100px] text-md'>
                          Action
                        </TableHead>
                      </TableRow>
                    </TableHeader>
                    <TableBody>
                      {domainDetails.domainRecords.map((record, index) => (
                        <TableRow key={index}>
                          <TableCell className='text-md !border-0 '>
                            {record.type}
                          </TableCell>
                          <TableCell className='font-mono text-md !border-0 '>
                            {record.key}
                          </TableCell>
                          <TableCell className='font-mono text-md !border-0 '>
                            {record.priority || '-'}
                          </TableCell>
                          <TableCell className='font-mono text-md !border-0 '>
                            {record.value}
                          </TableCell>
                          <TableCell className='text-right !border-0'>
                            <TooltipProvider>
                              <Tooltip>
                                <TooltipTrigger asChild>
                                  <Button
                                    variant='ghost'
                                    size='sm'
                                    onClick={() => copyDnsSettings(record)}
                                  >
                                    <Copy className='h-4 w-4' />
                                  </Button>
                                </TooltipTrigger>
                                <TooltipContent>Copy record</TooltipContent>
                              </Tooltip>
                            </TooltipProvider>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </div>

                <div className='flex justify-between items-center'>
                  <Button
                    className='!bg-secondary text-white'
                    type='submit'
                    onClick={checkDomain}
                    disabled={domainDetails.status === 'success'}
                  >
                    Check DNS Status
                  </Button>
                  {/* <Button onClick={sendDnsInstructions} variant='outline'>
                    <Send className='mr-2 h-4 w-4' /> Send Instructions to Admin
                  </Button> */}
                </div>
              </div>
            </AccordionContent>
          </AccordionItem>
        </Accordion>
      )}
    </div>
  );
};

export default observer(Setup);
