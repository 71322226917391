import MainChatUi from '@/components/template/MainChatUi';
import { User } from '@/types/user.types';
import { FC } from 'react';

const Chat: FC<{ data?: User | null }> = ({ data }) => {
  return (
    <MainChatUi
      conversationChannel='CHAT'
      title='Chat'
      showLeftPanel={false}
      userData={data}
    />
  );
};
export default Chat;
