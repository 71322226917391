import React, { useState, useEffect, useMemo } from 'react';
import { observer } from 'mobx-react-lite';
import {
  ChevronDown,
  Search,
  HelpCircle,
  AlertCircle,
  Trash2,
  AlertTriangle,
} from 'lucide-react';
import { Button } from '@/components/ui/button';
import { Input } from '@/components/ui/input';
import { Label } from '@/components/ui/label';
import { Switch } from '@/components/ui/switch';
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from '@/components/ui/popover';
import { Checkbox } from '@/components/ui/checkbox';
import { Card, CardContent, CardHeader, CardTitle } from '@/components/ui/card';
import { Alert, AlertDescription } from '@/components/ui/alert';
import {
  Tooltip,
  TooltipTrigger,
  TooltipContent,
  TooltipProvider,
} from '@/components/ui/tooltip';
import { useNavigate, useOutletContext, useParams } from 'react-router-dom';
import { listStore } from '@/state/ListStore';
import { contactStore } from '@/state/ContactStore';
import { useContact } from '@/hooks/useContact';
import { useList } from '@/hooks/useList';
// import { BASE_URL } from '@/constants';
import { v4 as uuidv4 } from 'uuid';
import { ListPayload, OutletContextType } from './types';
import { replaceRouteParam } from '@/utils/routes';
import routeNames from '@/routes/routeNames';
import { AuthStore } from '@/state/AuthenticationStore';

type UTMParameter = {
  id: string;
  name: string;
  type: string;
  name_value: string;
  value: string;
};

const Recipients = () => {
  // const [recipients, setRecipients] = useState<Recipient[]>([]);
  const [selectedRecipients, setSelectedRecipients] = useState<string[]>([]);
  const [excludedRecipients, setExcludedRecipients] = useState<string[]>([]);
  const [utmTracking, setUtmTracking] = useState(false);
  const [filterQuery, setFilterQuery] = useState('');
  const [globalSelectedValues, setGlobalSelectedValues] = useState<string[]>(
    [],
  );
  // const [utmURL, setUtmURL] = useState<string>('');
  const [finalList, setFinalList] = useState<ListPayload[]>([]);
  const [nextEnabled, setNextEnabled] = useState<boolean>(false);
  const [displayError, setDisplayError] = useState<boolean>(false);
  const { channel } = useParams();
  const { getLists } = useList();
  const {
    getContactStats,
    getContacts,
    checkIfContactsHavePhoneNumberOrEmail,
  } = useContact();
  const navigate = useNavigate();
  const { isNextClicked, setIsNextClicked } =
    useOutletContext<OutletContextType>();

  const goNext = () => {
    navigate(
      replaceRouteParam(
        `${routeNames.dashboard.emailMarketing}/campaign-flow/${channel}/campaign-message`,
        'workspaceId',
        AuthStore.user_workspace_info.active_workspace.workspace._id,
      ),
    );
  };

  useEffect(() => {
    if (isNextClicked) {
      if (nextEnabled) {
        goNext();
      } else {
        setDisplayError(true);
      }
      setIsNextClicked(false);
    }
  }, [isNextClicked]);

  const [utmParameters, setUtmParameters] = useState<UTMParameter[]>([
    {
      id: '1',
      type: 'default',
      name: 'Source',
      name_value: 'utm_source',
      value: 'GetSparky',
    },
    {
      id: '2',
      type: 'default',
      name: 'Medium',
      name_value: 'utm_medium',
      value: 'campaign',
    },
    {
      id: '3',
      type: 'optional',
      name: 'Campaign',
      name_value: 'utm_campaign',
      value: '',
    },
    { id: '4', type: 'optional', name: 'Id', name_value: 'utm_id', value: '' },
    {
      id: '5',
      type: 'optional',
      name: 'Term',
      name_value: 'utm_term',
      value: '',
    },
  ]);
  const [showPersonalDataWarning, setShowPersonalDataWarning] = useState(false);

  const listOptions = [
    {
      label: 'All Contacts',
      value: 'all',
      count: contactStore?.contactStats[0]?.count,
    },
    {
      label: 'My Contacts',
      value: 'my_contacts',
      count: contactStore?.contactStats[1]?.count,
    },
    ...(listStore.lists?.map(list => ({
      label: list.name,
      value: list._id,
      count: list.total_contacts,
    })) || []),
  ];

  useEffect(() => {
    getLists();
    getContactStats();
    getContacts();
  }, [
    contactStore?.contacts?.length,
    contactStore?.recentViewContacts?.length,
    contactStore?.myContacts?.length,
  ]);

  useEffect(() => {
    const index = finalList.findIndex(item => item.type == 'send_to');
    if (index == -1) {
      setNextEnabled(false);
    } else {
      setNextEnabled(true);
    }
  }, [finalList]);

  // useEffect(() => {
  //   setUtmURL(generateUTMUrl(BASE_URL, utmParameters));
  // });

  const RecipientSelector = ({
    value,
    onChange,
    placeholder,
    globalSelectedValues,
    onGlobalChange,
    dropdownId,
    finalList,
    onFinalListChange,
  }: {
    value: string[];
    onChange: (value: string[]) => void;
    placeholder: string;
    globalSelectedValues: string[];
    finalList: ListPayload[];
    onGlobalChange: (value: string[]) => void;
    onFinalListChange: (value: ListPayload[]) => void;
    dropdownId: string;
  }) => {
    const isFirstOptionSelected = useMemo(() => {
      return value.includes(listOptions[0]?.value);
    }, [value, listOptions]);

    const filteredRecipients = useMemo(() => {
      if (dropdownId == 'dropdown1') {
        return listOptions.filter(
          recipient =>
            recipient.label.toLowerCase().includes(filterQuery.toLowerCase()) &&
            (!globalSelectedValues.includes(recipient.value) ||
              value.includes(recipient.value)),
        );
      } else {
        return listOptions
          .filter(
            recipient =>
              recipient.label
                .toLowerCase()
                .includes(filterQuery.toLowerCase()) &&
              (!globalSelectedValues.includes(recipient.value) ||
                value.includes(recipient.value)),
          )
          .filter(recipient => recipient.value !== 'all');
      }
    }, [filterQuery, globalSelectedValues, value, listOptions, finalList]);

    const handleCheckedChange = (
      checked: boolean,
      recipientValue: string,
      Id: string,
    ) => {
      let updatedLocal = [];
      let updatedGlobal = [];
      let updatedFinalList: any[] = [];

      const listType: 'send_to' | 'dont_send_to' =
        Id === 'dropdown1' ? 'send_to' : 'dont_send_to';

      if (checked) {
        if (recipientValue === listOptions[0]?.value) {
          updatedLocal = [recipientValue];
          updatedGlobal = [recipientValue];
          updatedFinalList = [
            ...finalList,
            { value: recipientValue, type: listType },
          ];
        } else {
          updatedLocal = [...value, recipientValue];
          updatedGlobal = [...globalSelectedValues, recipientValue];
          updatedFinalList = [
            ...finalList,
            { value: recipientValue, type: listType },
          ];
        }
      } else {
        updatedLocal = value.filter(v => v !== recipientValue);
        updatedGlobal = globalSelectedValues.filter(v => v !== recipientValue);
        const index = finalList.findIndex(
          item => item.value == recipientValue && item.type == listType,
        );
        if (index) {
          finalList.splice(index, 1);
          updatedFinalList = [...finalList];
        }
      }

      if (channel) {
        checkIfContactsHavePhoneNumberOrEmail(channel, updatedFinalList);
      }

      onChange(updatedLocal);
      onGlobalChange(updatedGlobal);
      onFinalListChange(updatedFinalList);
    };

    return (
      <Popover>
        <PopoverTrigger asChild>
          <Button
            variant='outline'
            role='combobox'
            aria-expanded={false}
            className={`w-full justify-between ${
              displayError && dropdownId == 'dropdown1'
                ? 'border border-red-700'
                : ''
            }`}
          >
            {value.length > 0
              ? `${value.length} list${value.length > 1 ? 's' : ''} selected`
              : placeholder}
            <ChevronDown className='ml-2 h-4 w-4 shrink-0 opacity-50' />
          </Button>
        </PopoverTrigger>
        <PopoverContent className='w-[400px] p-0'>
          <div className='p-2'>
            <div className='flex items-center border rounded-md'>
              <Search className='w-4 h-4 ml-2 text-gray-400' />
              <Input
                placeholder='Filter'
                value={filterQuery}
                onChange={e => setFilterQuery(e.target.value)}
                className='border-0 focus-visible:ring-0 focus-visible:ring-offset-0'
              />
            </div>
          </div>
          <div className='max-h-[300px] overflow-auto'>
            {filteredRecipients &&
              filteredRecipients.map(recipient => {
                const isDisabled =
                  isFirstOptionSelected && !value.includes(recipient.value);
                return (
                  <div
                    key={recipient.value}
                    className='flex items-center space-x-2 p-2 hover:bg-gray-100'
                  >
                    <Checkbox
                      id={`${dropdownId}-${recipient.value}`}
                      checked={value.includes(recipient.value)}
                      onCheckedChange={checked => {
                        handleCheckedChange(
                          checked as boolean,
                          recipient.value,
                          dropdownId,
                        );
                      }}
                      disabled={isDisabled}
                    />
                    <label
                      htmlFor={`${dropdownId}-${recipient.value}`}
                      className='flex items-center justify-between w-full text-md font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70'
                    >
                      <span className='flex items-center'>
                        {recipient.label} ({recipient.count})
                      </span>
                    </label>
                  </div>
                );
              })}
          </div>
        </PopoverContent>
      </Popover>
    );
  };

  const getTooltipContent = (paramName: string) => {
    const tooltips: Record<string, string> = {
      Source:
        "<strong>Use utm_source to identify where the traffic is coming from.</strong><br/>This is usually the website or platform that links to your content, such as 'Google', 'Facebook', or 'Newsletter'.",
      Medium:
        "<strong>Use utm_medium to specify the type of marketing channel.</strong><br/>Examples include 'email', 'cpc' (cost-per-click), 'social', or 'display'. This helps you understand which channel drove the traffic.",
      Campaign:
        "<strong>Use utm_campaign to identify the specific campaign that is driving the traffic.</strong><br/>For example, 'holiday_sale' or 'spring_launch'. This helps you track the performance of different campaigns.",
      Id: '<strong>Use utm_id as a unique identifier for the campaign.</strong><br/>This is especially helpful for differentiating similar campaigns or tracking ads individually.',
      Term: "<strong>Use utm_term to track keywords for paid search campaigns.</strong><br/>This helps you see which keywords are performing best. Example: 'running_shoes'.",
      custom:
        "<strong>Add a custom parameter to track additional details unique to your campaign.</strong><br/>For example, 'product_variant' or 'region'.",
    };
    return tooltips[paramName] || tooltips['custom'];
  };

  const handleAddUTMParameter = () => {
    setUtmParameters([
      ...utmParameters,
      { id: uuidv4(), type: 'custom', name: '', name_value: '', value: '' },
    ]);
  };

  const handleRemoveUTMParameter = (id: string) => {
    setUtmParameters(utmParameters.filter(param => param.id !== id));
  };

  const handleUTMParameterChange = (
    id: string,
    field: 'name' | 'name_value' | 'value',
    value: string,
  ) => {
    setUtmParameters(
      utmParameters.map(param =>
        param.id === id ? { ...param, [field]: value } : param,
      ),
    );
  };

  // function generateUTMUrl(
  //   baseUrl: string,
  //   utmParameters: { id: string; name_value: string; value: string }[],
  // ): string {
  //   const url = new URL(baseUrl);

  //   utmParameters.forEach(param => {
  //     if (param.name_value && param.value) {
  //       const sanitizedName = param.name_value.trim().toLowerCase();
  //       const validName = sanitizedName.replace(/[^a-z0-9_]/gi, '');
  //       url.searchParams.append(validName, param.value.trim());
  //     }
  //   });

  //   return url.toString();
  // }

  return (
    <div className='max-w-4xl mx-auto p-6 mb-8'>
      <Card className='mb-6'>
        <CardHeader>
          <CardTitle className='text-secondary'>Audience</CardTitle>
        </CardHeader>
        <CardContent>
          <div className='space-y-4'>
            <div>
              <Label htmlFor='recipientList1'>Send to</Label>
              <RecipientSelector
                value={selectedRecipients}
                onChange={setSelectedRecipients}
                placeholder='Select a list or segment'
                globalSelectedValues={globalSelectedValues}
                onGlobalChange={setGlobalSelectedValues}
                dropdownId='dropdown1'
                finalList={finalList}
                onFinalListChange={setFinalList}
              />
              {displayError && (
                <div className='flex pt-1 flex-row'>
                  <AlertCircle className=' h-4 w-4 mr-1 text-red-700' />{' '}
                  <span className='text-xs text-red-700'>
                    You must include at least one list.
                  </span>
                </div>
              )}
            </div>

            <div>
              <Label htmlFor='recipientList2'>Don&apos;t send to</Label>
              <RecipientSelector
                value={excludedRecipients}
                onChange={setExcludedRecipients}
                placeholder='Select another list or segment'
                globalSelectedValues={globalSelectedValues}
                onGlobalChange={setGlobalSelectedValues}
                dropdownId='dropdown2'
                finalList={finalList}
                onFinalListChange={setFinalList}
              />
            </div>
            <div className='mt-4 p-4 bg-gray-100 rounded-lg'>
              <div className='flex items-center justify-between mb-2'>
                <span className='font-semibold'>Estimated recipient count</span>
                <TooltipProvider>
                  <Tooltip delayDuration={0}>
                    <TooltipTrigger asChild>
                      <HelpCircle className='h-4 w-4 text-gray-400' />
                    </TooltipTrigger>
                    <TooltipContent className='absolute right-[10px] w-[330px] h-[50px]'>
                      <p>
                        This is the estimated number of recipients based on your
                        selected and excluded lists.
                      </p>
                    </TooltipContent>
                  </Tooltip>
                </TooltipProvider>
              </div>
              <div className='text-4xl font-bold'>
                {contactStore.fetchingEstimatedRecipients ? (
                  <p className='text-lg text-black'>-</p>
                ) : (
                  <div>{contactStore.campaignSenderList.length}</div>
                )}
              </div>
            </div>
          </div>
        </CardContent>
      </Card>

      <Card className={`${utmTracking ? 'mb-[60px]' : 'mb-6'}`}>
        <CardHeader>
          <CardTitle className='text-blue-900'>Tracking</CardTitle>
        </CardHeader>
        <CardContent>
          <div className='space-y-4'>
            <div className='flex items-center space-x-2'>
              <Switch
                id='utm-tracking'
                checked={utmTracking}
                onCheckedChange={setUtmTracking}
                className='data-[state=checked]:bg-secondary'
              />
              <Label htmlFor='utm-tracking'>Enable UTM Tracking</Label>
            </div>
            <p className='text-md text-gray-500'>
              Links will include additional tracking information, called UTM
              parameters. This allows source tracking within third-party
              reporting tools such as Google Analytics.
            </p>

            {utmTracking && (
              <>
                <Alert
                  variant='default'
                  className='bg-yellow-50 border-yellow-100'
                >
                  <AlertTriangle className='h-4 w-4' />
                  <AlertDescription className='flex items-center justify-between'>
                    <span>Don&apos;t use personal data.</span>
                    <Button
                      variant='link'
                      className='h-auto p-0 text-secondary'
                      onClick={() =>
                        setShowPersonalDataWarning(!showPersonalDataWarning)
                      }
                    >
                      {showPersonalDataWarning ? 'Less' : 'Learn more'}
                    </Button>
                  </AlertDescription>
                </Alert>

                {showPersonalDataWarning && (
                  <Alert
                    variant='default'
                    className='bg-yellow-50 border-yellow-100'
                  >
                    <AlertDescription>
                      <p className='mb-2 text-md'>
                        In order to protect the privacy of your customers, do
                        not use any profile properties that include personal
                        data as custom tracking parameters.
                      </p>
                      <p className='mb-2 text-md'>
                        <strong>What is personal data?</strong>
                      </p>
                      <p className='text-md'>
                        Personal data includes things like names, addresses,
                        email addresses, or any similar data, including any
                        sensitive data or &quot;special categories&quot; of data
                        under applicable laws (like sexual orientation, gender
                        identity, religion, race, ethnicity, health data,
                        government ID number, etc.).
                      </p>
                    </AlertDescription>
                  </Alert>
                )}

                <div className='space-y-2'>
                  {utmParameters.map(param => (
                    <div
                      key={param.id}
                      className='grid grid-cols-[auto_1fr_1fr_auto] gap-2 items-center'
                    >
                      {param.type !== 'custom' ? (
                        <Checkbox
                          id={`utm-${param.id}`}
                          checked={
                            (param.name !== '' && param.value !== '') ||
                            param.type == 'default'
                          }
                          onCheckedChange={(checked: any) => {
                            if (!checked) {
                              handleUTMParameterChange(param.id, 'name', '');
                              handleUTMParameterChange(param.id, 'value', '');
                            }
                          }}
                          disabled={param.type == 'default'}
                        />
                      ) : (
                        <div className='h-4 w-4'></div>
                      )}
                      <div className='relative'>
                        {param.type == 'custom' ? (
                          <Input
                            placeholder='UTM parameter'
                            value={param.name_value}
                            onChange={e =>
                              handleUTMParameterChange(
                                param.id,
                                'name_value',
                                e.target.value,
                              )
                            }
                            className='pr-8 focus-visible:outline-none focus-visible:ring-0 focus-visible:ring-ring focus-visible:ring-offset-0 focus-within:border focus-within:border-secondary'
                          />
                        ) : (
                          <p className='text-md'>{`${param.name} (${param.name_value})`}</p>
                        )}
                        <TooltipProvider>
                          <Tooltip delayDuration={0}>
                            <TooltipTrigger asChild>
                              <Button
                                variant='ghost'
                                size='icon'
                                className='absolute right-1 top-1/2 -translate-y-1/2'
                              >
                                <HelpCircle className='h-4 w-4' />
                              </Button>
                            </TooltipTrigger>
                            <TooltipContent>
                              <p
                                className='max-w-xs'
                                dangerouslySetInnerHTML={{
                                  __html: getTooltipContent(param.name),
                                }}
                              />
                            </TooltipContent>
                          </Tooltip>
                        </TooltipProvider>
                      </div>
                      <Input
                        placeholder='Value'
                        value={param.value}
                        onChange={e =>
                          handleUTMParameterChange(
                            param.id,
                            'value',
                            e.target.value,
                          )
                        }
                        className='focus-visible:outline-none focus-visible:ring-0 focus-visible:ring-ring focus-visible:ring-offset-0 focus-within:border focus-within:border-secondary'
                      />
                      {param.type === 'custom' && (
                        <Button
                          variant='ghost'
                          size='icon'
                          onClick={() => handleRemoveUTMParameter(param.id)}
                        >
                          <Trash2 className='h-4 w-4' />
                        </Button>
                      )}
                    </div>
                  ))}
                </div>

                <Button variant='outline' onClick={handleAddUTMParameter}>
                  Add UTM parameter
                </Button>
              </>
            )}
          </div>
        </CardContent>
      </Card>
    </div>
  );
};
export default observer(Recipients);
