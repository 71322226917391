/* eslint-disable @typescript-eslint/no-unused-vars */
import Button from '@/components/atoms/Button';
import ConfirmationModal from '@/components/atoms/modal/ConfirmationModal';
import { SOCKET_EVENT_NAMES } from '@/constants/socket.events';
import { useEmailMarketing } from '@/hooks/useEmailMarketing';
import { AuthStore } from '@/state/AuthenticationStore';
import { Icon } from '@iconify/react';
import { observer } from 'mobx-react-lite';
import { useRef, useState } from 'react';
import {
  Sheet,
  SheetContent,
  SheetFooter,
  SheetHeader,
  SheetTitle,
  SheetTrigger,
} from '@/components/ui/sheet';
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '@/components/ui/select';
import { Checkbox } from '@/components/ui/checkbox';
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from '@/components/ui/popover';
import Spinner from '@/components/atoms/Spinner';
import Text from '@/components/atoms/Text';
import { fetchRequestWithToken } from '@/config/axios';
import { notify } from '@/helpers/index';
import { emailMarketingStore } from '@/state/EmailMarketingStore';
import EmailEditor, { EditorRef, EmailEditorProps } from 'react-email-editor';
import { useNavigate, useParams } from 'react-router-dom';
import { ScrollArea } from '@/components/ui/scroll-area';
import { Label } from '@/components/ui/label';
import { Input } from '@/components/ui/input';
import { ChevronDown } from 'lucide-react';
import { Button as ShadcnButton } from '@/components/ui/button';

const EmailBuilder = () => {
  const emailEditorRef = useRef<EditorRef>(null);
  const [initializing, setInitializing] = useState(true);
  const [isOpen, setIsOpen] = useState(false);
  const [name, setName] = useState('');
  const [tName, setTName] = useState('');
  const [selectedTab, setSelectedTab] = useState('schedule');
  const [loadingMessage, setLoadingMessage] = useState('Saving Template...');
  const [email, setEmail] = useState('');
  const [type, setType] = useState('');
  const [status, setStatus] = useState('');

  const { saveTemplate, testEmail } = useEmailMarketing();

  const [selectedValues, setSelectedValues] = useState<string[]>([]);

  const options = [
    { value: 'autumn', label: 'Autumn' },
    { value: 'spring', label: 'Spring' },
    { value: 'summer', label: 'Summer' },
    { value: 'winter', label: 'Winter' },
    { value: 'anniversary', label: 'Anniversary' },
    { value: 'backtoschool', label: 'Back to School' },
    { value: 'birthday', label: 'Birthday' },
    { value: 'blackfriday', label: 'Black Friday' },
    { value: 'christmas', label: 'Christmas' },
    { value: 'cybermonday', label: 'Cyber Monday' },
    { value: 'easter', label: 'Easter' },
  ];

  const navigate = useNavigate();
  const { id } = useParams();
  const unlayer = emailEditorRef.current?.editor;

  const saveDesign = () => {
    unlayer?.exportHtml(async data => {
      if (!tName.trim() && !name.trim()) {
        notify('error', 'Please enter a name');
        return;
      }
      const { design, html } = data;
      const { rows } = design.body;
      let isEmpty = true;
      label: for (let i = 0; i < rows.length; i++) {
        const { columns } = rows[i];
        for (let j = 0; j < columns.length; j++) {
          const { contents } = columns[j];
          if (contents.length) {
            isEmpty = false;
            break label;
          }
        }
      }
      if (isEmpty) {
        notify(
          'error',
          'Please add content to the email template builder before saving',
        );
        return;
      }
      try {
        emailMarketingStore.setLoading(true);
        // TODO: uncomment this when we get pro plan
        // const container = document.createElement('div');
        // container.innerHTML = html;
        // document.body.appendChild(container);
        // const image = await htmlToImage.toPng(container);

        // const byteString = atob(image.split(',')[1]);
        // const arrayBuffer = new ArrayBuffer(byteString.length);
        // const intArray = new Uint8Array(arrayBuffer);

        // for (let i = 0; i < byteString.length; i++) {
        //   intArray[i] = byteString.charCodeAt(i);
        // }
        if (!design || !html) {
          notify('error', 'Invalid design data');
          return;
        }
        saveTemplate({
          name: id ? tName : name,
          type: type || 'basic',
          html,
          json: design,
          status: id ? status : 'Draft',
          tags: selectedValues,
          id,
          // TODO: uncomment this when we get pro plan
          // thumbnail: {
          //   buffer: intArray,
          //   filename: `${id ? tName : name}.png`,
          // },
        });
        // document.body.removeChild(container);
      } catch (error) {
        notify('error', 'Error: Failed to save template');
      }
    });
  };

  const onReady: EmailEditorProps['onReady'] = unlayer => {
    setInitializing(false);

    unlayer.registerCallback('image', async function (file: any, done: any) {
      try {
        done({ progress: 50, url: '' });
        const workspaceId =
          AuthStore.user_workspace_info.active_workspace.workspace._id;
        const response = await fetchRequestWithToken({
          url: `/api/upload/${workspaceId}`,
          method: 'POST',
          headers: { 'Content-Type': 'multipart/form-data' },
          data: {
            file: file?.attachments[0],
          },
        });
        done({ progress: 100, url: response.data.url });
      } catch (error) {
        notify('error', error as string);
        done({ progress: 0, url: '' });
      }
    });

    AuthStore?.socket?.emit(
      SOCKET_EVENT_NAMES.GET_EMAIL_TEMPLATE,
      { event_name: SOCKET_EVENT_NAMES.GET_EMAIL_TEMPLATE, data: { id } },
      (response: any) => {
        unlayer?.loadDesign(response?.json);
        setTName(response?.name);
        setStatus(response?.status);
        setType(response?.type);
        setSelectedValues(response?.tags);
      },
    );
  };

  const handleTestEmail = () => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    if (emailRegex.test(email.trim()) && id) {
      testEmail({ email, templateId: id });
    } else if (!id) {
      notify('error', 'Please make sure your email template is saved');
    } else {
      notify('error', 'Please enter a valid email address');
    }
  };

  const toggleOption = (value: string) => {
    setSelectedValues(prev =>
      prev.includes(value)
        ? prev.filter(item => item !== value)
        : [...prev, value],
    );
  };

  return (
    <>
      {initializing && (
        <div className='h-screen flex flex-col justify-center items-center bg-white absolute inset-0 z-10'>
          <div className='space-y-2'>
            <Spinner color='blue' size={40} />
            <Text size='sm' color='text-black animate-pulse'>
              {id ? 'Loading Email Template' : 'Initializing Email Builder'}...
            </Text>
          </div>
        </div>
      )}

      {emailMarketingStore.loading && (
        <div className='h-screen flex flex-col justify-center items-center bg-white absolute inset-0 z-10'>
          <div className='space-y-2'>
            <Spinner color='blue' size={40} />
            <Text size='sm' color='text-black animate-pulse'>
              {loadingMessage}
            </Text>
          </div>
        </div>
      )}
      <div className='flex h-[100vh]'>
        <div className='flex flex-col gap-y-6 px-4 w-[20%] bg-white py-2 relative'>
          <div
            onClick={() => navigate(-1)}
            className='w-[34px] h-[34px] cursor-pointer rounded-[4px] flex justify-center items-center bg-[#FBFCFD] border border-border'
          >
            <Icon
              icon='ph:arrow-left-light'
              width={18}
              height={18}
              color='#000000'
            />
          </div>
          <Text size='md' color='text-black'>
            Template
          </Text>
          <Input
            value={id ? tName : name}
            onChange={e => {
              if (id) {
                setTName(e.target.value);
              } else {
                setName(e.target.value);
              }
            }}
            placeholder='Template Name'
            type='text'
            className=' py-2 w-full focus-visible:outline-none focus-visible:ring-0
               focus-visible:ring-ring focus-visible:ring-offset-0 focus-within:border focus-within:border-secondary'
          />
          <Select value={type} onValueChange={value => setType(value)}>
            <SelectTrigger
              className='focus:ring-0 focus:ring-offset-0
              focus-within:border focus-within:border-secondary'
            >
              <SelectValue placeholder='Template type' />
            </SelectTrigger>
            <SelectContent>
              <SelectItem value='basic layout'>Basic layout</SelectItem>
              <SelectItem value='deals'>Deals</SelectItem>
              <SelectItem value='confirmation'>Confirmation</SelectItem>
              <SelectItem value='outreach'>Outreach</SelectItem>
              <SelectItem value='reminders'>Reminders</SelectItem>
              <SelectItem value='sales'>Sales</SelectItem>
            </SelectContent>
          </Select>
          <Popover>
            <PopoverTrigger asChild>
              <ShadcnButton
                variant='outline'
                className='w-full flex items-center justify-between  capitalize'
              >
                <span className='truncate overflow-hidden whitespace-nowrap'>
                  {selectedValues.length > 0
                    ? selectedValues.join(', ')
                    : 'Select Seasons & Holidays'}
                </span>
                <ChevronDown className='h-4 w-4 text-black/50 flex-shrink-0 ml-2' />
              </ShadcnButton>
            </PopoverTrigger>
            <PopoverContent className='w-[var(--radix-popover-trigger-width)]'>
              <div className='flex flex-col gap-2'>
                {options.map(option => (
                  <label
                    key={option.value}
                    className='flex items-center space-x-2 cursor-pointer'
                  >
                    <Checkbox
                      checked={selectedValues.includes(option.value)}
                      onCheckedChange={() => toggleOption(option.value)}
                    />
                    <span>{option.label}</span>
                  </label>
                ))}
              </div>
            </PopoverContent>
          </Popover>

          <Button
            onClick={() => setIsOpen(true)}
            text={id ? 'Update Template' : 'Save Template'}
            type='button'
            disabled={!name && !tName}
          />
          <hr className='h-px border-0 bg-border'></hr>
          <Text size='md' color='text-black'>
            Test Email
          </Text>
          <Input
            value={email}
            onChange={e => setEmail(e.target.value)}
            placeholder='Email Address'
            type='email'
            className='py-2 w-full focus-visible:outline-none focus-visible:ring-0
               focus-visible:ring-ring focus-visible:ring-offset-0 focus-within:border focus-within:border-secondary'
          />
          <Button
            onClick={() => handleTestEmail()}
            text={'Send Email'}
            type='button'
            disabled={!email}
            loading={emailMarketingStore.sendingTestEmail}
            loadingText='Sending...'
          />
        </div>
        <Sheet>
          <SheetTrigger
            asChild
            className='flex flex-col absolute bottom-2  w-[20%] '
          >
            <div className='px-4'>
              <Button type='button' text='Schedule or send' />
            </div>
          </SheetTrigger>
          <SheetContent className='w-[300px] sm:w-[400px] max-h-screen flex flex-col'>
            <SheetHeader>
              <SheetTitle>Schedule or send compaign</SheetTitle>
            </SheetHeader>
            <ScrollArea className='flex-grow py-4'>
              <div>
                <div className='flex space-x-6 mb-6'>
                  <div
                    onClick={() => setSelectedTab('schedule')}
                    className={`flex flex-col p-2 border rounded-[10px] cursor-pointer ${
                      selectedTab === 'schedule'
                        ? 'border-secondary'
                        : 'border-gray-300'
                    }`}
                  >
                    <span className='font-medium text-[16px] text-black/90'>
                      Schedule
                    </span>
                    <p className='font-normal text-[12px] text-black/50'>
                      Choose a future date and time to send
                    </p>
                  </div>

                  <div
                    onClick={() => setSelectedTab('sendNow')}
                    className={`flex flex-col p-2 border rounded-[10px] cursor-pointer ${
                      selectedTab === 'sendNow'
                        ? 'border-secondary'
                        : 'border-gray-300'
                    }`}
                  >
                    <span className='font-medium text-[16px] text-black/90'>
                      Send now
                    </span>
                    <p className='font-normal text-[12px] text-black/50'>
                      Start sending your campaign immediately
                    </p>
                  </div>
                </div>
                {selectedTab === 'schedule' && (
                  <div className='mt-2 space-y-2'>
                    <Label
                      htmlFor='sendType'
                      className='flex items-center space-x-2'
                    >
                      <span>Type </span> <span className='text-red-500'>*</span>
                    </Label>
                    <Select name='sendType' required>
                      <SelectTrigger className='focus:ring-0 focus:ring-offset-0 focus-within:border focus-within:border-secondary'>
                        <SelectValue placeholder='Select type' />
                      </SelectTrigger>
                      <SelectContent>
                        <SelectItem value='Fixed send time'>
                          Fixed send time
                        </SelectItem>
                      </SelectContent>
                    </Select>
                    <Label
                      htmlFor='date'
                      className='flex items-center space-x-2'
                    >
                      <span>Date </span> <span className='text-red-500'>*</span>
                    </Label>
                    <Input
                      type='date'
                      placeholder='date'
                      className='focus-visible:outline-none focus-visible:ring-0 focus-visible:ring-ring focus-visible:ring-offset-0 focus-within:border focus-within:border-secondary'
                    />
                    <Label
                      htmlFor='time'
                      className='flex items-center space-x-2'
                    >
                      <span>Time</span>
                    </Label>
                    <Input
                      type='time'
                      placeholder='time'
                      className='focus-visible:outline-none focus-visible:ring-0 focus-visible:ring-ring focus-visible:ring-offset-0 focus-within:border focus-within:border-secondary'
                    />
                    <Label
                      htmlFor='sendType'
                      className='flex items-center space-x-2'
                    >
                      <span>Time zone </span>
                    </Label>
                    <Select name='Timezone' required>
                      <SelectTrigger className='focus:ring-0 focus:ring-offset-0 focus-within:border focus-within:border-secondary'>
                        <SelectValue placeholder='Select time zone' />
                      </SelectTrigger>
                      <SelectContent>
                        <SelectItem value='Fixed send time'>
                          {"Recipient's Local Time Zone"}
                        </SelectItem>
                      </SelectContent>
                    </Select>
                  </div>
                )}
              </div>
            </ScrollArea>
            {selectedTab === 'schedule' && (
              <SheetFooter className='flex !justify-start items-center'>
                <Button type='button' variant='outline' text='Cancel' />
                <Button
                  type='button'
                  text='Schedule campaign'
                  className='bg-black/90 hover:bg-black/80'
                />
              </SheetFooter>
            )}
          </SheetContent>
        </Sheet>
        <EmailEditor ref={emailEditorRef} onReady={onReady} />
      </div>
      <ConfirmationModal
        title='Save Template'
        content='Are you sure you want to save this template?'
        show={isOpen}
        setShow={setIsOpen}
        confirmText='Done'
        onConfirm={() => {
          setIsOpen(false);
          saveDesign();
        }}
      />
    </>
  );
};
export default observer(EmailBuilder);
