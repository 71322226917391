import EmptyList from '@/components/atoms/EmptyList';
import ConfirmationModal from '@/components/atoms/modal/ConfirmationModal';
import Spinner from '@/components/atoms/Spinner';
import { useEmailMarketing } from '@/hooks/useEmailMarketing';
import routeNames from '@/routes/routeNames';
import { AuthStore } from '@/state/AuthenticationStore';
import { emailMarketingStore } from '@/state/EmailMarketingStore';
import { replaceRouteParam } from '@/utils/routes';
import { observer } from 'mobx-react-lite';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button } from '@/components/ui/button';
import { Input } from '@/components/ui/input';
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '@/components/ui/select';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from '@/components/ui/table';
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogHeader,
  DialogTitle,
} from '@/components/ui/dialog';
import { Badge } from '@/components/ui/badge';
import {
  ArrowUpDown,
  ChevronDown,
  Copy,
  Edit,
  Eye,
  MoreVertical,
  PlusCircle,
  Search,
  Trash,
  X,
} from 'lucide-react';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from '@/components/ui/dropdown-menu';
import { Checkbox } from '@/components/ui/checkbox';
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from '@/components/ui/popover';
import {
  Pagination,
  PaginationContent,
  PaginationEllipsis,
  PaginationItem,
  PaginationLink,
  PaginationNext,
  PaginationPrevious,
} from '@/components/ui/pagination';

const Templates = () => {
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(false);
  const [isPreview, setIsPreview] = useState(false);
  const [isClone, setClone] = useState(false);
  const [templateToDelete, setTemplateToDelete] = useState<string | null>(null);
  const [templateToClone, setTemplateToClone] = useState<string | null>(null);
  const [templateToPreview, setTemplateToPreview] = useState<string | null>(
    null,
  );
  const [templateName, setTemplateName] = useState<string | null>(null);
  const [sortConfig, setSortConfig] = useState<{
    key: string;
    direction: 'asc' | 'desc';
  } | null>(null);

  const [selectedType, setSelectedType] = useState<string>('');
  const [selectedValues, setSelectedValues] = useState<string[]>([]);

  const { getTemplates, deleteTemplate, cloneTemplate } = useEmailMarketing();
  const templates = emailMarketingStore?.templates;

  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;

  const [searchTerm, setSearchTerm] = useState('');

  const handleDeleteClick = (id: string) => {
    setTemplateToDelete(id);
    setIsOpen(true);
  };

  const handleCloneClick = (id: string) => {
    setTemplateToClone(id);
    setClone(true);
  };

  const handleConfirmDelete = () => {
    if (templateToDelete) {
      deleteTemplate(templateToDelete);
      setTemplateToDelete(null);
    }
    setIsOpen(false);
  };

  const handleConfirmClone = (id: string) => {
    const template = templates?.find(temp => temp?._id === id);
    if (template) {
      cloneTemplate({
        name: `${template.name} - Clone`,
        type: template.type,
        html: template.html,
        json: template.json,
        status: template.status,
        tags: template.tags,
      });
      setTemplateToClone(null);
    }
    setClone(false);
  };

  const preview = (id: string) => {
    const template = templates?.find(temp => temp?._id === id);
    if (template) {
      setTemplateToPreview(template.html);
      setTemplateName(template?.name);
      setIsPreview(true);
    }
  };

  const filteredTemplates = templates?.filter(template => {
    const matchesSearch =
      template.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
      template.type.toLowerCase().includes(searchTerm.toLowerCase()) ||
      template.status.toLowerCase().includes(searchTerm.toLowerCase());

    const matchesType = selectedType ? template.type === selectedType : true;

    const matchesTags =
      selectedValues.length > 0
        ? selectedValues.every(tag => template.tags.includes(tag))
        : true;

    return matchesSearch && matchesType && matchesTags;
  });

  const totalTemplates = filteredTemplates?.length || 0;
  const totalPages = Math.ceil(totalTemplates / itemsPerPage);

  const handleSort = (key: string) => {
    setSortConfig(prev => {
      if (prev?.key === key) {
        return { key, direction: prev.direction === 'asc' ? 'desc' : 'asc' };
      }
      return { key, direction: 'asc' };
    });
  };

  const sortedTemplates = [...(filteredTemplates || [])].sort(
    (a: any, b: any) => {
      if (!sortConfig) return 0;
      const aValue = a[sortConfig.key];
      const bValue = b[sortConfig.key];

      if (aValue < bValue) return sortConfig.direction === 'asc' ? -1 : 1;
      if (aValue > bValue) return sortConfig.direction === 'asc' ? 1 : -1;
      return 0;
    },
  );

  const handlePageChange = (page: number) => {
    setCurrentPage(page);
  };
  useEffect(() => {
    getTemplates(currentPage, itemsPerPage);
  }, [currentPage, itemsPerPage]);

  const options = [
    { value: 'autumn', label: 'Autumn' },
    { value: 'spring', label: 'Spring' },
    { value: 'summer', label: 'Summer' },
    { value: 'winter', label: 'Winter' },
    { value: 'anniversary', label: 'Anniversary' },
    { value: 'backtoschool', label: 'Back to School' },
    { value: 'birthday', label: 'Birthday' },
    { value: 'blackfriday', label: 'Black Friday' },
    { value: 'christmas', label: 'Christmas' },
    { value: 'cybermonday', label: 'Cyber Monday' },
    { value: 'easter', label: 'Easter' },
  ];

  const toggleOption = (value: string) => {
    setSelectedValues(prev =>
      prev.includes(value)
        ? prev.filter(item => item !== value)
        : [...prev, value],
    );
  };

  return (
    <div className='p-6 min-h-screen overflow-y-auto bg-white'>
      {emailMarketingStore.loading ? (
        <div className='h-[80%] flex justify-center items-center bg-white my-auto'>
          <Spinner color='blue' size={50} />
        </div>
      ) : (
        <>
          <div className='flex justify-between items-center mb-6'>
            <h1 className='text-2xl font-bold'>Templates</h1>
            <Button
              onClick={() =>
                navigate(
                  replaceRouteParam(
                    routeNames.dashboard.emailTemplates,
                    'workspaceId',
                    AuthStore.user_workspace_info.active_workspace.workspace
                      ._id,
                  ),
                )
              }
              className='bg-secondary hover:bg-secondary/90 text-white text-md'
            >
              <PlusCircle className='mr-2 h-6 w-6' />
              New Template
            </Button>
          </div>

          <div className='flex gap-4 mb-6'>
            <div className='relative flex-1'>
              <Search className='absolute left-2 top-2.5 h-4 w-4 text-muted-foreground' />
              <Input
                placeholder='Search templates...'
                className='pl-8 pr-4 py-2 w-full focus-visible:outline-none focus-visible:ring-0
               focus-visible:ring-ring focus-visible:ring-offset-0 focus-within:border focus-within:border-secondary'
                value={searchTerm}
                onChange={e => {
                  setSearchTerm(e.target.value);
                  setCurrentPage(1);
                }}
              />
            </div>
            <Select
              value={selectedType}
              onValueChange={value => setSelectedType(value)}
            >
              <SelectTrigger
                className='w-[245px] focus:ring-0 focus:ring-offset-0
                           focus-within:border focus-within:border-secondary'
              >
                <SelectValue placeholder='Template type' />
              </SelectTrigger>
              <SelectContent>
                <SelectItem value='basic layout'>Basic layout</SelectItem>
                <SelectItem value='deals'>Deals</SelectItem>
                <SelectItem value='confirmation'>Confirmation</SelectItem>
                <SelectItem value='outreach'>Outreach</SelectItem>
                <SelectItem value='reminders'>Reminders</SelectItem>
                <SelectItem value='sales'>Sales</SelectItem>
              </SelectContent>
            </Select>
            <Popover>
              <PopoverTrigger asChild>
                <Button
                  variant='outline'
                  className='w-[245px] flex items-center justify-between  capitalize'
                >
                  <span className='truncate overflow-hidden whitespace-nowrap'>
                    {selectedValues.length > 0
                      ? selectedValues.join(', ')
                      : 'Select Seasons & Holidays'}
                  </span>
                  <ChevronDown className='h-4 w-4 text-black/50 flex-shrink-0 ml-2' />
                </Button>
              </PopoverTrigger>
              <PopoverContent className='w-[245px] p-2'>
                <div className='flex flex-col gap-2'>
                  {options.map(option => (
                    <label
                      key={option.value}
                      className='flex items-center space-x-2 cursor-pointer'
                    >
                      <Checkbox
                        checked={selectedValues.includes(option.value)}
                        onCheckedChange={() => toggleOption(option.value)}
                      />
                      <span>{option.label}</span>
                    </label>
                  ))}
                </div>
              </PopoverContent>
            </Popover>
          </div>
          {selectedType ? (
            <div className='flex items-center gap-2 mb-6'>
              <Badge
                variant='outline'
                className='flex items-center gap-1 text-md'
              >
                {selectedType}
              </Badge>
              <Button
                variant='link'
                className='text-blue h-auto p-0 text-md'
                onClick={() => setSelectedType('')}
              >
                Clear
              </Button>
            </div>
          ) : (
            <div className='mb-12' />
          )}

          {!filteredTemplates?.length ? (
            <EmptyList
              listName={''}
              title='There are no templates'
              subTitle='Add templates to see them here'
              height='40vh'
            />
          ) : (
            <div className='rounded-md border mb-16'>
              <Table>
                <TableHeader className='font-medium text-md'>
                  <TableRow>
                    <TableHead
                      onClick={() => handleSort('name')}
                      className='cursor-pointer text-left'
                    >
                      <div className='flex items-center justify-between'>
                        <p> Name</p>
                        <ArrowUpDown
                          className={`h-4 w-4 inline-block transform ${
                            sortConfig?.key === 'name' ? 'rotate-180' : ''
                          } ${
                            sortConfig?.key === 'name'
                              ? sortConfig?.direction === 'asc'
                                ? 'rotate-180'
                                : ''
                              : 'opacity-0'
                          }`}
                        />
                      </div>
                    </TableHead>
                    <TableHead
                      onClick={() => handleSort('type')}
                      className='cursor-pointer  text-left'
                    >
                      <div className='flex items-center justify-between'>
                        <p>Type</p>
                        <ArrowUpDown
                          className={`h-4 w-4 inline-block transform ${
                            sortConfig?.key === 'type'
                              ? sortConfig.direction === 'asc'
                                ? 'rotate-180'
                                : ''
                              : 'opacity-0'
                          }`}
                        />
                      </div>
                    </TableHead>
                    <TableHead
                      onClick={() => handleSort('status')}
                      className='cursor-pointer  text-left'
                    >
                      <div className='flex items-center justify-between'>
                        <p>Status</p>
                        <ArrowUpDown
                          className={`h-4 w-4 inline-block transform ${
                            sortConfig?.key === 'status'
                              ? sortConfig.direction === 'asc'
                                ? 'rotate-180'
                                : ''
                              : 'opacity-0'
                          }`}
                        />
                      </div>
                    </TableHead>
                    <TableHead
                      onClick={() => handleSort('createdBy')}
                      className='cursor-pointer text-left'
                    >
                      <div className='flex items-center justify-between'>
                        <p>Created By</p>
                        <ArrowUpDown
                          className={`h-4 w-4 inline-block transform  ${
                            sortConfig?.key === 'createdBy'
                              ? sortConfig.direction === 'asc'
                                ? 'rotate-180'
                                : ''
                              : 'opacity-0'
                          }`}
                        />
                      </div>
                    </TableHead>
                    <TableHead className='text-right'>Actions</TableHead>
                  </TableRow>
                </TableHeader>

                <TableBody className='text-md'>
                  {sortedTemplates
                    ?.slice(
                      (currentPage - 1) * itemsPerPage,
                      currentPage * itemsPerPage,
                    )
                    ?.map(template => (
                      <TableRow key={template._id}>
                        <TableCell className='!border-0'>
                          <button
                            onClick={() =>
                              navigate(
                                replaceRouteParam(
                                  `${routeNames.dashboard.emailTemplates}/${template._id}`,
                                  'workspaceId',
                                  AuthStore.user_workspace_info.active_workspace
                                    .workspace._id,
                                ),
                              )
                            }
                            className='font-medium hover:underline text-left'
                          >
                            {template?.name}
                          </button>
                        </TableCell>
                        <TableCell className='!border-0'>
                          {template?.type || '-'}
                        </TableCell>
                        <TableCell className='!border-0'>
                          <Badge
                            variant={
                              template?.status === 'New'
                                ? 'default'
                                : 'secondary'
                            }
                          >
                            {template?.status || '-'}
                          </Badge>
                        </TableCell>
                        <TableCell className='!border-0'>
                          {template?.createdBy?.user_name || '-'}
                        </TableCell>
                        <TableCell className='text-right !border-0'>
                          <DropdownMenu>
                            <DropdownMenuTrigger asChild>
                              <Button
                                variant='outline'
                                size='icon'
                                className='h-8 w-8'
                              >
                                <MoreVertical className='h-4 w-4' />
                              </Button>
                            </DropdownMenuTrigger>
                            <DropdownMenuContent align='end'>
                              <DropdownMenuItem
                                onClick={() => preview(template._id)}
                                className='text-md'
                              >
                                <Eye className='mr-2 h-4 w-4' />
                                Preview
                              </DropdownMenuItem>
                              <DropdownMenuItem
                                onClick={() =>
                                  navigate(
                                    replaceRouteParam(
                                      `${routeNames.dashboard.emailTemplates}/${template._id}`,
                                      'workspaceId',
                                      AuthStore.user_workspace_info
                                        .active_workspace.workspace._id,
                                    ),
                                  )
                                }
                                className='text-md'
                              >
                                <Edit className='mr-2 h-4 w-4' />
                                Edit
                              </DropdownMenuItem>
                              <DropdownMenuItem
                                onClick={() => handleCloneClick(template._id)}
                                className='text-md'
                              >
                                <Copy className='mr-2 h-4 w-4' />
                                Clone
                              </DropdownMenuItem>
                              <DropdownMenuItem
                                onClick={() => handleDeleteClick(template._id)}
                                className='text-red-500 text-md'
                              >
                                <Trash className='mr-2 h-4 w-4 text-red-500' />
                                Delete
                              </DropdownMenuItem>
                            </DropdownMenuContent>
                          </DropdownMenu>
                        </TableCell>
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
              <Pagination className='flex justify-end border border-b-0 border-x-0 py-2 border-t-1'>
                <PaginationContent>
                  <PaginationItem>
                    <PaginationPrevious
                      href='#'
                      onClick={() => handlePageChange(currentPage - 1)}
                      className={`${
                        currentPage === 1 ? 'pointer-events-none' : ''
                      } text-md`}
                    />
                  </PaginationItem>
                  {[...Array(totalPages)].map((_, i) => (
                    <PaginationItem key={i}>
                      <PaginationLink
                        href='#'
                        onClick={() => handlePageChange(i + 1)}
                        isActive={currentPage === i + 1}
                        className='text-md'
                      >
                        {i + 1}
                      </PaginationLink>
                    </PaginationItem>
                  ))}
                  {totalPages > 5 && <PaginationEllipsis />}
                  <PaginationItem>
                    <PaginationNext
                      href='#'
                      onClick={() => handlePageChange(currentPage + 1)}
                      className={`${
                        currentPage === totalPages ? 'pointer-events-none' : ''
                      } text-md`}
                    />
                  </PaginationItem>
                </PaginationContent>
              </Pagination>
              <ConfirmationModal
                title='Delete Template'
                content='Are you sure you want to delete this template?'
                show={isOpen}
                setShow={setIsOpen}
                confirmText='Done'
                onConfirm={handleConfirmDelete}
              />
              <ConfirmationModal
                title='Clone Template'
                content='Are you sure you want to clone this template?'
                show={isClone}
                setShow={setClone}
                confirmText='Done'
                onConfirm={() => handleConfirmClone(templateToClone as string)}
              />
              <Dialog open={isPreview} onOpenChange={setIsPreview}>
                <DialogContent className='bg-white border-none p-8 max-w-[1000px] max-h-[90vh] overflow-y-auto [&>button]:hidden'>
                  <DialogHeader className='flex flex-row justify-between items-center'>
                    <DialogTitle className='text-2xl font-semibold text-secondary'>
                      {templateName}
                    </DialogTitle>
                    <DialogClose asChild>
                      <Button variant='ghost' size='icon'>
                        <X className='h-6 w-6' />
                      </Button>
                    </DialogClose>
                  </DialogHeader>
                  <div
                    dangerouslySetInnerHTML={{
                      __html: templateToPreview as string,
                    }}
                  />
                </DialogContent>
              </Dialog>
            </div>
          )}
        </>
      )}
    </div>
  );
};
export default observer(Templates);
