import Avatar from '@/components/atoms/Avatar';
import { useAuth } from '@/hooks/useAuth';
import routeNames from '@/routes/routeNames';
import { AuthStore } from '@/state/AuthenticationStore';
import { replaceRouteParam } from '@/utils/routes';
import { Menu, Popover, Transition } from '@headlessui/react';
import { Fragment } from 'react';
import { useNavigate } from 'react-router-dom';

function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(' ');
}

const ProfileMenuItem = () => {
  const navigate = useNavigate();
  const { logout } = useAuth();
  const user = AuthStore.currentUser;

  const userNavigation = [
    { name: 'Your Profile', href: '#' },
    { name: 'Settings', href: '#' },
    { name: 'Sign out', href: '#' },
  ];

  return (
    <Popover>
      <Menu as='div' className='flex-shrink-0'>
        <Menu.Button className='group relative p-4 h-[64px]' aria-hidden='true'>
          <Avatar
            className='relative z-10'
            src={user?.profile_picture_url}
            size='lg'
            alt={user?.user_name}
            online
            color='primary'
            textColor='white'
          />
        </Menu.Button>

        <Transition
          as={Fragment}
          enter='transition ease-out duration-100'
          enterFrom='transform opacity-0 scale-95'
          enterTo='transform opacity-100 scale-100'
          leave='transition ease-in duration-75'
          leaveFrom='transform opacity-100 scale-100'
          leaveTo='transform opacity-0 scale-95'
        >
          <Menu.Items className='absolute  z-[99999] right-10  w-48 origin-top-right  overflow-hidden rounded-[8px] bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none'>
            {userNavigation?.map(item => (
              <Menu.Item key={item.name}>
                {({ active }) => (
                  <p
                    className={classNames(
                      active
                        ? 'bg-secondary text-[#FFFFFF]'
                        : 'text-opacity-90',
                      'block py-2 px-4 text-[#222] cursor-pointer',
                    )}
                    onClick={() => {
                      const workspaceId =
                        AuthStore.user_workspace_info.active_workspace.workspace
                          ._id;
                      if (item?.name === 'Sign out') {
                        logout();
                      }
                      if (item?.name === 'Settings') {
                        navigate(
                          replaceRouteParam(
                            routeNames.dashboard.settings,
                            'workspaceId',
                            workspaceId,
                          ),
                        );
                      }
                      if (item.name === 'Your Profile') {
                        navigate(
                          replaceRouteParam(
                            routeNames.dashboard.agentProfile + '/' + user?._id,
                            'workspaceId',
                            workspaceId,
                          ),
                        );
                      }
                    }}
                  >
                    {item?.name}
                  </p>
                )}
              </Menu.Item>
            ))}
          </Menu.Items>
        </Transition>
      </Menu>
    </Popover>
  );
};
export default ProfileMenuItem;
