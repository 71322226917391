import MainChatUi from '@/components/template/MainChatUi';

const WhatsApp = () => {
  return (
    <>
      <MainChatUi conversationChannel='WHATSAPP' title='Whatsapp' />
    </>
  );
};
export default WhatsApp;
