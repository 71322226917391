import { fetchRequestWithToken } from '@/config/axios';
import { SOCKET_EVENT_NAMES } from '@/constants/socket.events';
import { notify } from '@/helpers/index';
import { ContactType } from '@/pages/Contacts';
import routeNames from '@/routes/routeNames';
import { AuthStore } from '@/state/AuthenticationStore';
import { contactStore } from '@/state/ContactStore';
import { SocketResponse } from '@/types/socketResponse.type';
import { User } from '@/types/user.types';
import { replaceRouteParam } from '@/utils/routes';
import { FormikHelpers } from 'formik';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useList } from '../useList';
import { ListPayload } from '@/pages/EmailMarketing/types';

export type CreatePayload = {
  user_name: string;
  email: string;
  contact_type: 'User';
};

export const useContact = () => {
  const [open, setOpen] = useState(false);
  const [openImport, setOpenImport] = useState(false);
  const { getLists } = useList();
  const navigate = useNavigate();

  const closeModal = () => {
    setOpen(false);
  };
  const openModal = () => {
    setOpen(true);
  };

  const openModalImport = () => {
    setOpenImport(true);
  };
  const closeModalImport = () => {
    setOpenImport(false);
  };

  const createContact = (
    data: CreatePayload,
    opt?: FormikHelpers<CreatePayload>,
  ) => {
    contactStore.setCreating(true);
    fetchRequestWithToken({
      url: `/api/contacts`,
      method: 'POST',
      data: {
        user_name: data.user_name,
        email: data.email,
        contact_type: 'User',
      },
    })
      .then(({ data }) => {
        closeModal();
        opt?.resetForm({ values: '' as any });
        contactStore.setContacts([data, ...contactStore.contacts]);
        navigate(
          replaceRouteParam(
            routeNames.dashboard.userProfile + '/' + data._id,
            'workspaceId',
            AuthStore.user_workspace_info?.active_workspace?.workspace?._id,
          ),
        );
      })
      .catch(() => {
        notify('error', 'Error creating contact. Please try again');
      })
      .finally(() => {
        contactStore.setCreating(false);
      });
  };

  const getContacts = (queries?: string) => {
    contactStore.setFetchingContact(true);
    fetchRequestWithToken({
      url: `/api/contacts${queries || ''}`,
      method: 'GET',
    })
      .then(response => {
        const _response = response?.data?.data;

        if (response.config.url) {
          const url = response.config.baseURL + response.config.url;
          const newUrl = new URL(url);
          const parsedSearchParams = new URLSearchParams(newUrl.search);
          const type = parsedSearchParams.get('contactType') as ContactType;

          if (type === 'my') {
            contactStore.setMyContacts(_response);
          } else if (type === 'recently-viewed') {
            contactStore.setRecentViewContacts(_response);
          } else if (type === 'all') {
            contactStore.setContacts(_response);
          }
          contactStore.setContacts(_response);
        }
        contactStore.setTotalCount(response?.data.meta.totalCount);
      })
      .catch(() => {
        //...
      })
      .finally(() => {
        contactStore.setFetchingContact(false);
      });
  };

  const getContact = (_id: string) => {
    contactStore.setFetchingUser(true);
    fetchRequestWithToken({
      url: `/api/contacts/${_id}`,
      method: 'GET',
    })
      .then(response => {
        if (response.data) {
          contactStore.setContact(response?.data);
        } else {
          navigate('/404');
        }
      })
      .catch(() => {
        notify('error', 'Error: Failed to fetch contact');
      })
      .finally(() => {
        contactStore.setFetchingUser(false);
      });
  };

  const getOrders = (_id: string, filter?: Record<string, string | number>) => {
    contactStore.setFetchingOrders(true);

    const queryString = filter
      ? '?' + new URLSearchParams(filter as Record<string, string>).toString()
      : '';

    fetchRequestWithToken({
      url: `/api/contacts/get-orders/${_id}${queryString}`,
      method: 'GET',
    })
      .then(response => {
        if (response.data) {
          contactStore.setOrders(response.data);
        } else {
          contactStore.setOrders([]);
        }
      })
      .catch(() => {
        notify('error', 'Failed to fetch orders');
      })
      .finally(() => {
        contactStore.setFetchingOrders(false);
      });
  };

  const checkPhoneNumberAvailabilty = (data: any): Promise<boolean> => {
    return new Promise(resolve => {
      AuthStore?.socket?.emit(
        'phone_number_availability',
        { event_name: 'phone_number_availability', data: data },
        (response: SocketResponse<any>) => {
          if (response?.data == null) {
            notify('error', 'Phone number already in use');
            resolve(false);
          } else {
            resolve(true);
          }
        },
      );
    });
  };

  const checkIfContactsHavePhoneNumberOrEmail = (
    channel: string,
    lists: ListPayload[],
  ) => {
    contactStore.setFetchingEstimatedRecipients(true);
    AuthStore.socket?.emit(
      'phone_number_or_email',
      { event_name: 'phone_number_or_email', data: { channel, lists } },
      (response: any) => {
        if (response.data) {
          contactStore.setCampaignSenderList(response.data);
        } else {
          contactStore.setCampaignSenderList([]);
        }
        contactStore.setFetchingEstimatedRecipients(false);
      },
    );
  };

  const updateContact = (data: any) => {
    contactStore.setUpdatingContact(true);
    AuthStore?.socket?.emit(
      'update_user_info',
      { event_name: 'update_user_info', data: data },
      (response: SocketResponse<any>) => {
        if (response.data.status == 500) {
          notify('error', response.data.error);
        } else {
          contactStore.setContact(response.data);
        }
        contactStore.setUpdatingContact(false);
      },
    );
  };

  const deleteContacts = (_id: string) => {
    fetchRequestWithToken({
      url: `/api/contacts/${_id}`,
      method: 'DELETE',
    })
      .then(() => {
        getLists();
        getContactStats();
        navigate(routeNames.dashboard.contacts);
      })
      .catch(() => {
        notify('error', 'Error: Failed to delete contact');
      });
  };

  const blockContact = (_id: string) => {
    AuthStore?.socket?.emit(
      SOCKET_EVENT_NAMES.BLOCK_UNBLOCK_CONTACT,
      {
        event_name: SOCKET_EVENT_NAMES.BLOCK_UNBLOCK_CONTACT,
        data: {
          is_blocked: contactStore?.contact?.is_blocked ? false : true,
          id: _id,
        },
      },
      (response: any) => {
        if (response.error) {
          notify('error', response.error);
          return;
        } else if (response.data) {
          notify(
            'success',
            `Contact ${
              response.data?.is_blocked ? 'blocked' : 'unblocked'
            } successfully`,
          );
          contactStore.setContact(response.data);
        }
      },
    );
  };

  const importContacts = (data: File | null) => {
    contactStore.setUploadingContact(true);
    fetchRequestWithToken({
      url: `/api/contacts/upload`,
      method: 'POST',
      headers: {
        'Content-Type': 'multipart/form-data',
      },
      data: {
        file: data,
      },
    })
      .then(() => {
        notify('loading', 'Importing contacts...', {
          duration: Infinity,
          id: 'import-contacts-toast',
        });
        closeModalImport();
        getContacts('?contactType=all');
      })
      .catch(() => {
        notify('error', 'Error: Failed to import contacts');
      })
      .finally(() => {
        contactStore.setUploadingContact(false);
      });
  };

  function handleViewContact(id: string, isOnMyContact = false) {
    AuthStore.socket?.emit(
      SOCKET_EVENT_NAMES.CREATE_RECENTLY_VIEWED_CONTACTS,
      {
        data: {
          contact: id,
        },
        event: SOCKET_EVENT_NAMES.CREATE_RECENTLY_VIEWED_CONTACTS,
      },
      (response: SocketResponse<User>) => {
        if (response.data) {
          contactStore.setRecentViewContacts([
            response.data,
            ...contactStore.recentViewContacts,
          ]);
        }
      },
    );
    navigate(
      replaceRouteParam(
        routeNames.dashboard.userProfile + '/' + id,
        'workspaceId',
        AuthStore.user_workspace_info?.active_workspace?.workspace?._id,
      ),
      {
        state: {
          isOnMyContact,
        },
      },
    );
  }

  function handleSaveToMyContactList(contacts: string[]) {
    AuthStore.socket?.emit(
      SOCKET_EVENT_NAMES.SAVE_TO_MY_CONTACT_LIST,
      {
        data: {
          contacts,
        },
        event: SOCKET_EVENT_NAMES.SAVE_TO_MY_CONTACT_LIST,
      },
      (response: SocketResponse<User>) => {
        if (response.data) {
          contactStore.setMyContacts([
            response.data,
            ...(contactStore?.myContacts ?? []),
          ]);
        }
      },
    );
    notify('success', 'Saved to My Contacts');
  }

  function handleSaveToList(contacts: string[], listId: string) {
    AuthStore.socket?.emit(
      SOCKET_EVENT_NAMES.SAVE_TO_LIST,
      {
        data: {
          contacts,
          listId,
        },
        event: SOCKET_EVENT_NAMES.SAVE_TO_LIST,
      },
      (response: SocketResponse<User>) => {
        if (response.data) {
          notify('success', 'Saved to List');
          getLists();
        } else {
          notify('error', 'Error: Failed to save contact to list');
        }
      },
    );
  }

  const assignContact = (userId: string, contactId: string) => {
    AuthStore.socket?.emit(
      SOCKET_EVENT_NAMES.ASSIGN_CONTACT,
      {
        event_name: SOCKET_EVENT_NAMES.ASSIGN_CONTACT,
        data: {
          contactId,
          userId,
        },
      },
      (response: SocketResponse<User>) => {
        if (response.error || !response.data) {
          notify('error', response.error ?? 'something went wrong');
          return;
        }
        const existingContacts = contactStore?.myContacts?.filter(c => {
          return c._id !== response.data?._id;
        });
        contactStore.setMyContacts([response.data, ...existingContacts]);
        contactStore.setContact(response.data);
        notify('success', `Contact successfully assigned`);
      },
    );
  };

  const getContactStats = () => {
    fetchRequestWithToken({
      url: `/api/contacts/stats`,
      method: 'GET',
    })
      .then(response => {
        contactStore.setContactStats(response.data.data);
      })
      .catch(() => {
        //...
      });
  };

  const deleteContactFromList = (listId: string, contacts: string[]) => {
    AuthStore.socket?.emit(
      SOCKET_EVENT_NAMES.DELETE_CONTACT_FROM_LIST,
      {
        data: {
          contacts,
          listId,
        },
        event: SOCKET_EVENT_NAMES.DELETE_CONTACT_FROM_LIST,
      },
      (response: SocketResponse<User>) => {
        if (response.data) {
          getContacts(`?list=${listId}`);
          getLists();
          notify('success', 'Contact deleted successfully');
        } else {
          notify('error', 'Error: Failed to delete contact from list');
        }
      },
    );
  };

  return {
    open,
    openImport,
    getContacts,
    getContactStats,
    getContact,
    getOrders,
    createContact,
    updateContact,
    checkPhoneNumberAvailabilty,
    checkIfContactsHavePhoneNumberOrEmail,
    deleteContacts,
    blockContact,
    importContacts,
    closeModal,
    openModal,
    closeModalImport,
    openModalImport,
    handleViewContact,
    assignContact,
    handleSaveToMyContactList,
    handleSaveToList,
    deleteContactFromList,
  };
};
