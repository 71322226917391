import React, { FC, InputHTMLAttributes } from 'react';

interface SendMessageIconProps extends InputHTMLAttributes<HTMLInputElement> {
  isActive?: boolean;
}

export const SendMessageIcon: FC<SendMessageIconProps> = ({ isActive }) => {
  return (
    <svg
      className={`${isActive ? 'text-lightBlue' : ''}`}
      width='19'
      height='18'
      viewBox='0 0 19 18'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M18.2969 8.23047C18.7188 8.40625 18.9648 8.82812 19 9.25C19 9.70703 18.7188 10.1289 18.2969 10.3047L2.54688 17.0547C2.40625 17.125 2.26562 17.1602 2.125 17.1602C1.80859 17.1602 1.49219 17.0195 1.28125 16.7734C0.964844 16.457 0.894531 15.9297 1.10547 15.5078L4.375 9.25L1.10547 3.02734C0.894531 2.60547 0.964844 2.07812 1.28125 1.72656C1.49219 1.48047 1.80859 1.375 2.125 1.375C2.26562 1.375 2.40625 1.41016 2.54688 1.48047L18.2969 8.23047ZM3.32031 3.66016L5.81641 8.40625H14.4648L3.32031 3.66016ZM5.81641 10.0938L3.32031 14.875L14.4648 10.0938H5.81641Z'
        fill='#7E8B99'
      />
    </svg>
  );
};
