/* eslint-disable @typescript-eslint/no-explicit-any */
import Modal from '@/components/atoms/modal';
import ConfirmationModal from '@/components/atoms/modal/ConfirmationModal';
import { NewListForm } from '@/components/template/forms/NewListForm';
import { CreateList } from '@/hooks/useList';
import { contactStore } from '@/state/ContactStore';
import { listStore } from '@/state/ListStore';
import { Menu, Popover, Transition } from '@headlessui/react';
import { Icon } from '@iconify/react';
import { observer } from 'mobx-react-lite';
import { FC, Fragment, useState } from 'react';
import { MdDelete, MdEdit } from 'react-icons/md';
import { useParams } from 'react-router-dom';

function classNames(...classes: any) {
  return classes.filter(Boolean).join(' ');
}

type ListActionProps = {
  handleDelete: (action: string) => void;
  handleEdit: (data: CreateList) => void;
  isDisabled?: boolean;
};

const ListActions: FC<ListActionProps> = ({
  handleDelete,
  handleEdit,
  isDisabled,
}) => {
  const [isDelete, setIsDelete] = useState(false);
  const { listId } = useParams();
  const [isEditing, setIsEditing] = useState(false);
  const { id: userId } = useParams<{ id: string }>();

  const filter_info = [
    {
      title: 'Delete List',
      icon: <MdDelete className='w-4 h-4 text-current' />,
      isDisabled: isDisabled,
      action: 'delete',
    },
    {
      title: 'Edit List',
      icon: <MdEdit className='w-4 h-4 text-current' />,
      isDisabled: false,
      action: 'edit',
    },
  ];

  return (
    <div>
      {listId && (
        <Popover>
          <Menu as='div' className='flex-shrink-0 relative'>
            <div>
              <Menu.Button
                className={classNames(
                  'px-2 py-[7px] cursor-pointer rounded-[4px] shadow border border-border flex justify-center items-center',
                )}
                aria-hidden='true'
              >
                <Icon
                  icon='mdi:dots-vertical'
                  color='#DFE1E6'
                  height={18}
                  width={18}
                />
              </Menu.Button>
            </div>

            <Transition
              as={Fragment}
              enter='transition ease-out duration-100'
              enterFrom='transform opacity-0 scale-95'
              enterTo='transform opacity-100 scale-100'
              leave='transition ease-in duration-75'
              leaveFrom='transform opacity-100 scale-100'
              leaveTo='transform opacity-0 scale-95'
            >
              <Menu.Items
                className={classNames(
                  'absolute right-0 z-10 mt-2 w-[160px] origin-top-right divide-y divide-grey-light overflow-hidden rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none',
                )}
              >
                {filter_info.map(item => (
                  <Menu.Item key={item.title}>
                    {({ active }) => (
                      <div
                        onClick={() => {
                          if (item.action === 'delete') {
                            setIsDelete(true);
                          }
                          if (item.action === 'edit') {
                            setIsEditing(true);
                          }
                        }}
                        className={classNames(
                          active
                            ? 'bg-secondary text-[#FFFFFF]'
                            : 'text-opacity-90',
                          'block py-2 px-4 text-[#222] cursor-pointer',
                          item?.isDisabled && 'pointer-events-none bg-gray-300',
                        )}
                      >
                        <div className='flex items-center space-x-[7px]'>
                          <span>{item.icon}</span>
                          <span className='text-[14px] leading-[20px]'>
                            {item.action === 'edit' &&
                            contactStore?.contact?.is_blocked
                              ? 'Unblock'
                              : item.title}
                          </span>
                        </div>
                      </div>
                    )}
                  </Menu.Item>
                ))}
              </Menu.Items>
            </Transition>
          </Menu>
        </Popover>
      )}

      <Modal
        show={isEditing}
        openModal={() => setIsEditing(true)}
        closeModal={() => setIsEditing(false)}
        title='Edit List'
        className='z-[10000] inline-block py-6 my-8 w-[100%] max-w-[440px] text-left align-center  transition-all transform bg-white shadow-xl rounded-[7px]'
      >
        <NewListForm
          initialList={listStore.lists?.find(list => list._id === listId)}
          closeModal={() => setIsEditing(false)}
          handleSubmitList={(data: CreateList) => {
            handleEdit(data);
            setIsEditing(false);
          }}
        />
      </Modal>

      <ConfirmationModal
        title='Delete List'
        content='Are you sure you want to delete this list?'
        show={isDelete}
        setShow={setIsDelete}
        confirmText='Delete'
        onConfirm={() => {
          handleDelete(userId as string);
          setIsDelete(false);
        }}
      />
    </div>
  );
};

export default observer(ListActions);
