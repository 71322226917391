import { notify } from '@/helpers/index';
import { AuthStore } from '@/state/AuthenticationStore';
import { CONVERSATION_CHANNEL } from '@/types/conversation.types';
import { SocketResponse } from '@/types/socketResponse.type';

type SMSPayload = {
  contact_id_list: string[];
  text: string;
  conversation_channel: CONVERSATION_CHANNEL;
};

export const useSMS = () => {
  const send = async (data: SMSPayload, channel: CONVERSATION_CHANNEL) => {
    AuthStore.socket?.emit(
      'new_bulk_messages',
      {
        event_name: 'new_bulk_messages',
        data,
      },
      (response: SocketResponse<any>) => {
        if (response.data) {
          notify('loading', `sending ${channel} messages. please wait`, {
            id: 'sending-sms-toast',
            duration: Infinity,
          });
        }

        if (response.error) {
          notify('error', response.error);
        }
      },
    );
  };

  return {
    send,
  };
};
