import { Button } from '@/components/ui/button';
import { Input } from '@/components/ui/input';
import { Checkbox } from '@/components/ui/checkbox';
import { observer } from 'mobx-react-lite';
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '@/components/ui/select';
import { AlertCircle, MoreVertical, Plus, Search, X } from 'lucide-react';
import { useNavigate, useOutletContext, useParams } from 'react-router-dom';
import { replaceRouteParam } from '@/utils/routes';
import routeNames from '@/routes/routeNames';
import { AuthStore } from '@/state/AuthenticationStore';
import { emailMarketingStore, Template } from '@/state/EmailMarketingStore';
import { useEffect, useRef, useState } from 'react';
import { useEmailMarketing } from '@/hooks/useEmailMarketing';
import { OutletContextType } from '../types';
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from '@/components/ui/dialog';
import { EmojiHappyIcon } from '@heroicons/react/outline';
import { emojiList } from '@/constants';
import EmojiPickerModal from '@/components/atoms/modal/EmojiPickerModal';
import { useOutsideClickDetector } from '@/hooks/useOutsideClickDetector';
import { Tab, TabPanel } from '@/components/molecules/Tab';

const CampaignMessage = () => {
  const navigate = useNavigate();
  const templates = emailMarketingStore?.templates;
  const { getTemplates } = useEmailMarketing();
  const { channel } = useParams();
  const { isNextClicked, setIsNextClicked } =
    useOutletContext<OutletContextType>();
  const [isPreview, setIsPreview] = useState<boolean>(false);
  const [templateToPreviewOnModal, setTemplateToPreviewOnModal] = useState();
  const [templateToPreview, setTemplateToPreview] = useState();
  const [templateName, setTemplateName] = useState<string>();
  const [currentTemplate, setCurrentTemplate] = useState();
  const [template, setTemplate] = useState<Template>();
  const [subjectLine, setSubjectLine] = useState(
    emailMarketingStore.emailParams.subject_line,
  );
  const [previewText, setPreviewText] = useState(
    emailMarketingStore.emailParams.preview_text,
  );
  const [senderName, setSenderName] = useState(
    emailMarketingStore.emailParams.sender_name,
  );
  const [senderEmail, setSenderEmail] = useState(
    emailMarketingStore.sender_email,
  );
  const [showEmojiPicker, setShowEmojiPicker] = useState<boolean>(false);
  const subjectLineRef = useRef(null);
  const tabPanelOptions = ['desktop', 'mobile'];
  const [activeTab, setActiveTab] = useState(tabPanelOptions[0]);
  const [createdAt, setCreatedAt] = useState('');
  const [updatedAt, setUpdatedAt] = useState('');
  const [displayErrorMsg1, setDisplayErrorMsg1] = useState<boolean>(false);
  const [displayErrorMsg2, setDisplayErrorMsg2] = useState<boolean>(false);
  const [displayErrorMsg3, setDisplayErrorMsg3] = useState<boolean>(false);
  const { getDomain } = useEmailMarketing();

  useEffect(() => {
    getDomain();
  }, []);

  useEffect(() => {
    if (isNextClicked) {
      if (subjectLine !== '' && senderName !== '') {
        goNext();
      }
      if (subjectLine == '') {
        setDisplayErrorMsg1(true);
      }
      if (senderName == '') {
        setDisplayErrorMsg2(true);
      }
      if (senderEmail == '') {
        setDisplayErrorMsg3(true);
      }
      setIsNextClicked(false);
    }
  }, [isNextClicked]);

  useEffect(() => {
    getTemplates();
  }, [templates]);

  const goNext = () => {
    navigate(
      replaceRouteParam(
        `${routeNames.dashboard.emailMarketing}/campaign-flow/${channel}/campaign-review`,
        'workspaceId',
        AuthStore.user_workspace_info.active_workspace.workspace._id,
      ),
    );
  };

  const emailBuilder = () => {
    navigate(
      replaceRouteParam(
        `${routeNames.dashboard.emailMarketing}/campaign-flow/${channel}/campaign-message/in-flow-email-builder`,
        'workspaceId',
        AuthStore.user_workspace_info.active_workspace.workspace._id,
      ),
    );
  };

  const handle_emoji_click = (character: string) => {
    setSubjectLine(subjectLine + character);
  };

  const preview = (id: string) => {
    const template = templates?.find(temp => temp?._id === id);
    if (template) {
      setCurrentTemplate(template.html);
      setTemplateToPreviewOnModal(template.html);
      setTemplateName(template?.name);
      setCreatedAt(template.createdAt);
      setUpdatedAt(template.updatedAt);
      setIsPreview(true);
      setTemplate(template);
    }
  };

  const useTemplate = () => {
    setTemplateToPreview(currentTemplate);
    if (template) {
      emailMarketingStore.setTemplate(template);
    }
    setIsPreview(false);
  };

  useEffect(() => {
    if (emailMarketingStore.templateOnDemand?.html) {
      setTemplateToPreview(emailMarketingStore.templateOnDemand.html);
    }
  }, [emailMarketingStore.templateOnDemand]);

  useEffect(() => {
    const emailParameters = {
      subject_line: subjectLine,
      preview_text: previewText,
      sender_name: senderName,
    };
    if (emailParameters) {
      emailMarketingStore.setEmailParams(emailParameters);
    }
  }, [subjectLine, previewText, senderName, senderEmail]);

  useOutsideClickDetector(subjectLineRef, () => {
    setShowEmojiPicker(false);
  });

  return (
    <div className='min-h-screen bg-background'>
      {/* Main Content */}
      <div className='container mx-auto p-6'>
        <div className='grid grid-cols-2 gap-8'>
          {/* Left Column - Email Message */}
          <div className='space-y-6'>
            <div className='flex items-center gap-2'>
              <h2 className='text-lg font-semibold'>Email message</h2>
            </div>
            <div className='space-y-4 relative'>
              <div className='space-y-2'>
                <label className='text-md font-medium flex items-center gap-1'>
                  Subject line
                  <span className='text-red-500'>*</span>
                </label>
                <div className='flex gap-2'>
                  <Input
                    required
                    value={subjectLine}
                    onChange={e => setSubjectLine(e.target.value)}
                    className={`focus-visible:outline-none focus-visible:ring-0 ${
                      displayErrorMsg1 ? 'border border-red-700' : ''
                    } focus-visible:ring-ring focus-visible:ring-offset-0 focus-within:border focus-within:border-secondary`}
                  />
                  <Button
                    onClick={() => setShowEmojiPicker(current => !current)}
                    variant='outline'
                    size='icon'
                  >
                    <EmojiHappyIcon className='h-4 w-4' />
                  </Button>
                </div>
                {displayErrorMsg1 && (
                  <div className='flex flex-row'>
                    <AlertCircle className=' h-4 w-4 mr-1 text-red-700' />{' '}
                    <span className='text-xs text-red-700'>
                      Subject line is required
                    </span>
                  </div>
                )}
              </div>
              {showEmojiPicker && (
                <div
                  ref={subjectLineRef}
                  className='w-[364px] h-[250px] left-[100px] border-[#E6E6E6] border overflow-y-auto absolute bg-white'
                >
                  <EmojiPickerModal
                    emojiList={emojiList}
                    handleEmojiClick={handle_emoji_click}
                  />
                </div>
              )}
              <div className='space-y-2'>
                <label className='text-md font-medium'>Preview text</label>
                <Input
                  value={previewText}
                  onChange={e => setPreviewText(e.target.value)}
                  className='focus-visible:outline-none focus-visible:ring-0 focus-visible:ring-ring focus-visible:ring-offset-0 focus-within:border focus-within:border-secondary'
                />
              </div>
              <div className='space-y-2'>
                <label className='text-md font-medium flex items-center gap-1'>
                  Sender name
                  <span className='text-red-500'>*</span>
                </label>
                <Input
                  required
                  className={`focus-visible:outline-none ${
                    displayErrorMsg2 ? 'border border-red-700' : ''
                  } focus-visible:ring-0 focus-visible:ring-ring focus-visible:ring-offset-0 focus-within:border focus-within:border-secondary`}
                  value={senderName}
                  onChange={e => setSenderName(e.target.value)}
                />
                {displayErrorMsg2 && (
                  <div className='flex flex-row'>
                    <AlertCircle className=' h-4 w-4 mr-1 text-red-700' />{' '}
                    <span className='text-xs text-red-700'>
                      This field is required.
                    </span>
                  </div>
                )}
              </div>
              <div className='space-y-2'>
                <label className='text-md font-medium flex items-center gap-1'>
                  Sender email
                  <span className='text-red-500'>*</span>
                </label>
                <Input
                  required
                  className={`focus-visible:outline-none ${
                    displayErrorMsg3 ? 'border border-red-700' : ''
                  } focus-visible:ring-0 focus-visible:ring-ring focus-visible:ring-offset-0 focus-within:border focus-within:border-secondary`}
                  value={`@${senderEmail}`}
                  onChange={e => setSenderEmail(e.target.value)}
                  disabled={true}
                />
              </div>
              <div className='flex items-center space-x-2'>
                <Checkbox id='reply-to' />
                <label
                  htmlFor='reply-to'
                  className='text-md font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70'
                >
                  Use as reply-to
                </label>
              </div>
            </div>
          </div>

          {/* Right Column - Saved Templates */}
          <div className='space-y-6'>
            <div className='flex items-center justify-between'>
              <div className='flex items-center gap-4'>
                <h2 className='text-lg font-semibold'>Saved templates</h2>
              </div>
              <div className='flex items-center gap-2'>
                <Button
                  onClick={emailBuilder}
                  variant='outline'
                  className='gap-2'
                >
                  <Plus className='h-4 w-4' />
                  Create blank email
                </Button>
                <Button variant='ghost' size='icon'>
                  <MoreVertical className='h-4 w-4' />
                </Button>
              </div>
            </div>
            {!templateToPreview && (
              <div className='flex gap-2'>
                <div className='flex-1'>
                  <div className='relative'>
                    <Search className='absolute left-2 top-2.5 h-4 w-4 text-muted-foreground' />
                    <Input
                      placeholder='Search'
                      className='pl-8 focus-visible:outline-none focus-visible:ring-0 focus-visible:ring-ring focus-visible:ring-offset-0 focus-within:border focus-within:border-secondary'
                    />
                  </div>
                </div>
                <div className='flex items-center gap-2'>
                  <Select defaultValue='newest'>
                    <SelectTrigger
                      className='focus:ring-0 focus:ring-offset-0
              focus-within:border focus-within:border-secondary w-[180px]'
                    >
                      <SelectValue placeholder='Sort by' />
                    </SelectTrigger>
                    <SelectContent>
                      <SelectItem value='newest'>
                        Edited: newest first
                      </SelectItem>
                      <SelectItem value='oldest'>
                        Edited: oldest first
                      </SelectItem>
                      <SelectItem value='az'>Name: A to Z</SelectItem>
                      <SelectItem value='za'>Name: Z to A</SelectItem>
                    </SelectContent>
                  </Select>
                </div>
              </div>
            )}
            {emailMarketingStore.templateOnDemand == null ? (
              <div>
                {emailMarketingStore.template ? (
                  <div className='border rounded-lg p-4'>
                    <div
                      className='rounded-lg overflow-hidden'
                      dangerouslySetInnerHTML={{
                        __html: emailMarketingStore.template.html as string,
                      }}
                    />
                  </div>
                ) : (
                  <div className='grid grid-cols-3 gap-4'>
                    {templates &&
                      templates.map((template, index) => (
                        <div
                          key={index}
                          onClick={() => preview(template._id)}
                          className='border rounded-lg p-4 hover:shadow-md transition-shadow'
                        >
                          <h3 className='font-semibold mb-2'>
                            {template.name}
                          </h3>
                          <p className='text-md text-muted-foreground'>
                            Last edited:{' '}
                            {new Date(template.updatedAt).toLocaleString(
                              'default',
                              { month: 'short' },
                            )}{' '}
                            {new Date(template.updatedAt).getDate().toString()}{' '}
                            {new Date(template.updatedAt).toLocaleString(
                              'en-US',
                              {
                                hour: 'numeric',
                                minute: '2-digit',
                                hour12: true,
                              },
                            )}
                          </p>
                        </div>
                      ))}
                  </div>
                )}
              </div>
            ) : (
              <div className='border rounded-lg p-4'>
                <div
                  className='rounded-lg overflow-hidden'
                  dangerouslySetInnerHTML={{
                    __html: emailMarketingStore.templateOnDemand
                      ?.html as string,
                  }}
                />
              </div>
            )}
          </div>
        </div>
      </div>
      <Dialog open={isPreview} onOpenChange={setIsPreview}>
        <DialogContent className='bg-white flex flex-col border-none p-8 max-w-[1000px] max-h-[90vh] overflow-y-auto [&>button]:hidden'>
          <DialogHeader className='flex flex-row justify-between items-center'>
            <DialogTitle className='text-2xl font-semibold text-secondary'>
              {templateName}
            </DialogTitle>
            <DialogClose asChild>
              <Button variant='ghost' size='icon'>
                <X className='h-6 w-6' />
              </Button>
            </DialogClose>
          </DialogHeader>
          <div className='w-full h-full flex flex-row'>
            <div className='w-[30%] h-full'>
              <div className=''>
                created at:{' '}
                {new Date(createdAt).toLocaleString('default', {
                  month: 'short',
                })}{' '}
                {new Date(createdAt).getDate().toString()}{' '}
                {new Date(createdAt).toLocaleString('en-US', {
                  hour: 'numeric',
                  minute: '2-digit',
                  hour12: true,
                })}
              </div>
              <div>
                updated at:{' '}
                {new Date(updatedAt).toLocaleString('default', {
                  month: 'short',
                })}{' '}
                {new Date(updatedAt).getDate().toString()}{' '}
                {new Date(updatedAt).toLocaleString('en-US', {
                  hour: 'numeric',
                  minute: '2-digit',
                  hour12: true,
                })}
              </div>
            </div>
            {templateToPreviewOnModal && (
              <div className='h-full w-[70%] flex flex-col'>
                <div className='flex justify-center mb-3'>
                  <div className='w-full'>
                    <Tab
                      onTabChange={(index: any) => {
                        setActiveTab(tabPanelOptions[index]);
                      }}
                    >
                      <TabPanel title='Desktop'></TabPanel>
                      <TabPanel title='Mobile'></TabPanel>
                    </Tab>
                  </div>
                </div>
                <div
                  className={`flex ${
                    activeTab == tabPanelOptions[1]
                      ? 'w-[270px] ml-[200px]'
                      : 'w-full'
                  }`}
                >
                  <div
                    className={`h-full overflow-y-auto rounded-lg`}
                    dangerouslySetInnerHTML={{
                      __html: templateToPreviewOnModal as string,
                    }}
                  />
                </div>
              </div>
            )}
          </div>
          <DialogFooter className='flex flex-row-reverse'>
            <Button variant={'outline'} onClick={() => setIsPreview(false)}>
              <p>Cancel</p>
            </Button>
            <Button
              className='bg-secondary text-white hover:bg-secondary/80'
              variant={'default'}
              onClick={useTemplate}
            >
              <p className='text-white'> Use Template</p>
            </Button>
          </DialogFooter>
        </DialogContent>
      </Dialog>
    </div>
  );
};
export default observer(CampaignMessage);
