import { useState } from 'react';
import CampaignHeader from './CampaignHeader';
import { Outlet, useParams } from 'react-router-dom';

const CampaignFlow = () => {
  const { channel } = useParams();
  const [isNextClicked, setIsNextClicked] = useState(false);
  const [isSendClicked, setIsSendClicked] = useState(false);
  return (
    <div className='w-full h-full flex flex-col'>
      <div className='sticky z-10 top-0'>
        <CampaignHeader
          channel={channel}
          setIsNextClicked={setIsNextClicked}
          setIsSendClicked={setIsSendClicked}
        />
      </div>
      <div className='h-full w-full'>
        <Outlet
          context={{
            channel,
            isNextClicked,
            setIsNextClicked,
            isSendClicked,
            setIsSendClicked,
          }}
        />
      </div>
    </div>
  );
};

export default CampaignFlow;
