import { OrderIcon } from '@/assets/Icons/inbox';
import Spinner from '@/components/atoms/Spinner';
import Text from '@/components/atoms/Text';
import { STATUS } from '@/constants';
import { contactStore } from '@/state/ContactStore';
import { observer } from 'mobx-react-lite';

const OrderList = () => {
  const orders = contactStore.orders;

  return (
    <div className='flex flex-col py-4'>
      {contactStore.fetchingOrders ? (
        <Spinner size={40} />
      ) : (
        <div>
          {orders.length <= 0 ? (
            <div className='w-full h-full flex items-center justify-center'>
              <p>No orders to display</p>
            </div>
          ) : (
            <div>
              {orders.map(order => (
                <div key={order.order_number}>
                  <Text size='lg' color='text-[#161518]'>
                    {new Date(order.date as Date).toLocaleString('default', {
                      month: 'long',
                    })}{' '}
                    {new Date(order.date as Date).getFullYear().toString()}
                  </Text>
                  <div className='flex flex-col gap-y-4 py-2'>
                    <div className='flex justify-between items-center'>
                      <Text size='md' className='font-medium text-[#38228]'>
                        {order.order_number}
                      </Text>
                      <Text
                        size='xs'
                        className='text-[#898989] text-[12px] font-normal font-medium leading-[16px]'
                      >
                        {new Date(order.date as Date).toLocaleString(
                          'default',
                          { month: 'short' },
                        )}{' '}
                        {new Date(order.date as Date).getDate().toString()},{' '}
                        {new Date(order.date as Date).getFullYear().toString()}{' '}
                        at{' '}
                        {new Date(order.date as Date).toLocaleString('en-US', {
                          hour: 'numeric',
                          minute: '2-digit',
                          hour12: true,
                        })}
                      </Text>
                    </div>
                    <div className='flex flex-col gap-y-1 px-[15px]'>
                      <Text size='md' className='font-medium text-[#8A2C0D]'>
                        {order.items_list.length} ITEMS
                      </Text>
                      {order.items_list.map((item: any, index: any) => (
                        <div className='flex items-start space-x-2' key={index}>
                          <div className='mt-1.5'>
                            <OrderIcon />
                          </div>
                          <div className='flex flex-col  gap-y-1.5'>
                            <Text
                              size='sm'
                              className='font-normal text-[12px] text-[#656971]'
                            >
                              {item.name}
                            </Text>
                            <div className='flex space-x-2 items-center'>
                              <div className='px-[6px] rounded-[6px] border border-border text-[#171F27] font-500 text-[10px]'>
                                {item.weight}
                              </div>
                              <Text
                                size='xs'
                                className='font-medium text-[#000000]'
                              >
                                ${item.price}
                              </Text>
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                  <hr className='h-px border-0 bg-[#ECEDF0] my-4 mx-2'></hr>
                  <div className='flex justify-between items-center px-2'>
                    <div
                      className={`${
                        order.status == STATUS.PENDING
                          ? 'bg-lightOrange'
                          : order.status == STATUS.SOLD
                          ? 'bg-lightGreen'
                          : 'bg-lightRed'
                      } rounded-md pb-1 px-2 `}
                    >
                      <span
                        className={`${
                          order.status == STATUS.PENDING
                            ? 'text-darkOrange'
                            : order.status == STATUS.SOLD
                            ? 'text-darkGreen'
                            : 'text-darkRed'
                        } font-medium capitalize text-[12px]`}
                      >
                        {order.status}
                      </span>
                    </div>
                    <div className='flex flex-col items-end gap-y-1'>
                      <Text size='sm' color='text-[#656971]'>
                        Subtotal: {order.sub_total}
                      </Text>
                      <Text size='sm' color='text-[#656971]'>
                        Tax: {order.tax}
                      </Text>
                      <Text size='sm' color='text-[#000000]'>
                        Total: {order.total}
                      </Text>
                    </div>
                  </div>
                  <hr className='h-px border-0 bg-[#ECEDF0] my-4'></hr>
                </div>
              ))}
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default observer(OrderList);
