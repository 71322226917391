import Container from '@/components/atoms/Container';
import Placeholder from '@/components/atoms/Placeholder';
import Text from '@/components/atoms/Text';
import Tile from '@/components/atoms/Tile';
import ToolTip from '@/components/atoms/Tooltip';
import DateFilter from '@/components/molecules/DateFilter';
import { useChatConversation } from '@/hooks/useChatConversation';
import { useContact } from '@/hooks/useContact';
import { useList } from '@/hooks/useList';
import { contactStore } from '@/state/ContactStore';
import { ConStore } from '@/state/ConversationStore';
import { listStore } from '@/state/ListStore';
import { CONVERSATION_CHANNEL } from '@/types/conversation.types';
import { observer } from 'mobx-react-lite';
import { useEffect, useState } from 'react';

const Overview = () => {
  const { getLists } = useList();
  const { getContactStats } = useContact();
  const [open, setOpen] = useState(true);
  const { get_open_conversations, get_closed_conversations } =
    useChatConversation();
  const myContactCount =
    contactStore?.contactStats?.find(
      (contact: any) => contact.name === 'My Contacts',
    )?.count || 0;
  const allContactCount =
    contactStore?.contactStats?.find(
      (contact: any) => contact.name === 'All Contacts',
    )?.count || 0;

  const lists = listStore.lists.length;

  useEffect(() => {
    getLists();
    getContactStats();
  }, []);

  useEffect(() => {
    get_open_conversations({
      channel: CONVERSATION_CHANNEL.CHAT,
    });
    get_open_conversations({
      channel: CONVERSATION_CHANNEL.SMS,
    });
    get_closed_conversations({
      channel: CONVERSATION_CHANNEL.CHAT,
    });
    get_closed_conversations({
      channel: CONVERSATION_CHANNEL.SMS,
    });
    ConStore.set_selected_conversation_id(null);
  }, [ConStore.sorted_by, CONVERSATION_CHANNEL]);

  return (
    <div>
      <div className='w-full relative hidden md:flex flex-col'>
        <Container>
          <div className='flex justify-between items-center p-[24px]'>
            <div className='flex justify-between items-center space-x-16'>
              <span className='text-xl leading-7 py-4'>Overview</span>
              <div className='w-[164px]'>
                <Placeholder fTitle='Me' sTitle='Team' />
              </div>
            </div>
            <ToolTip title='Coming soon'>
              <DateFilter />
            </ToolTip>
          </div>
          <div
            className={`grid grid-cols-4 w-full transition-max-h overflow-hidden duration-500 ease-in-out ${
              open ? 'max-h-96' : 'max-h-0'
            }`}
          >
            <Tile
              name='All contacts'
              content={`${allContactCount}`}
              statistics={true}
              statisticValue={+24.5}
              borderTop={true}
              borderLeft={true}
              borderRight={true}
            />
            <Tile
              name='My contacts'
              content={`${myContactCount}`}
              statistics={true}
              statisticValue={-21.2}
              borderTop={true}
            />
            <Tile
              name='Replied messages'
              content='- -'
              statistics={true}
              statisticValue={undefined}
              borderTop={true}
              borderLeft={true}
            />
            <Tile
              name='Contact groups'
              content={`${lists}`}
              borderTop={true}
              borderLeft={true}
              borderRight={true}
            />
            <Tile
              name='Waiting for reply'
              content='- -'
              borderTop={true}
              borderLeft={true}
              borderRight={true}
            />
            <Tile name='Average response time' content='- -' borderTop={true} />
            <Tile
              name='Open'
              content={`${
                ConStore.all_open_chat_conversations_count +
                ConStore.all_open_sms_conversations_count
              } conversations`}
              borderTop={true}
              borderLeft={true}
            />
            <Tile
              name='Archived'
              content={`${
                ConStore.all_closed_chat_conversations_count +
                ConStore.all_closed_sms_conversations_count
              } conversations`}
              borderTop={true}
              borderLeft={true}
              borderRight={true}
            />
          </div>
        </Container>
        <div className='flex justify-center items-center'>
          <div
            onClick={() => setOpen(!open)}
            className='duration-300 ease-in-out border border-border 
            shadow-md  py-1 rounded-[4px] w-[60px]
           text-center bg-white z-50 absolute cursor-pointer'
          >
            <Text color='text-black' size='md'>
              {open ? 'Hide' : 'Show'}
            </Text>
          </div>
        </div>
      </div>
      <div className='md:hidden mt-4'>
        <div className='border border-y-1 border-border '>
          <div className='flex justify-between items-center p-4'>
            <div className='flex flex-col gap-y-2'>
              <Text color='text-black' size='md'>
                Overview
              </Text>
              <DateFilter />
            </div>
            <div className='w-[164px]'>
              <Placeholder fTitle='Me' sTitle='Team' />
            </div>
          </div>
        </div>
        <div className='grid grid-cols-2'>
          <Tile
            name='All contacts'
            content={`${allContactCount}`}
            statistics={true}
            statisticValue={+24.5}
          />
          <Tile
            name='My contacts'
            content={`${myContactCount}`}
            statistics={true}
            statisticValue={-21.2}
            borderLeft={true}
          />
          <Tile
            name='Replied messages'
            content='- -'
            statistics={true}
            statisticValue={undefined}
            borderTop={true}
          />
          <Tile
            name='Contact groups'
            content={`${lists}`}
            borderTop={true}
            borderLeft={true}
          />
          <Tile name='Waiting for reply' content='- -' borderTop={true} />
          <Tile
            name='Average response time'
            content='- -'
            borderTop={true}
            borderLeft={true}
          />
          <Tile
            name='Open'
            content={`${
              ConStore.all_open_chat_conversations_count +
              ConStore.all_open_sms_conversations_count
            } conversations`}
            borderTop={true}
            borderBottom={true}
          />
          <Tile
            name='Archived'
            content={`${
              ConStore.all_closed_chat_conversations_count +
              ConStore.all_closed_sms_conversations_count
            } conversations`}
            borderTop={true}
            borderBottom={true}
            borderLeft={true}
          />
        </div>
      </div>
    </div>
  );
};
export default observer(Overview);
