import Text from '@/components/atoms/Text';
import ToolTip from '@/components/atoms/Tooltip';
import { observer } from 'mobx-react-lite';
import React from 'react';

export interface FilterTabOption {
  id: number;
  title: string;
  icon?: React.ReactNode;
  type?: 'button' | 'tab';
  disabled?: boolean;
  mt?: number;
}

interface FilterProps {
  options: FilterTabOption[];
  activeTab: number;
  mt?: number;
  onChange: (id: number) => void;
}

const FilterTabs: React.FC<FilterProps> = ({
  options,
  activeTab,
  mt,
  onChange,
}) => {
  return (
    <div className='w-full flex items-center gap-2'>
      {options.map(option => (
        <div
          key={option.id}
          onClick={() => !option.disabled && onChange(option.id)}
          style={{ marginTop: `${mt}px` }}
          className={`${
            option.disabled
              ? 'bg-white cursor-not-allowed'
              : activeTab === option.id
              ? 'bg-[#033496] bg-opacity-5'
              : 'bg-white hover:bg-[#033496] hover:bg-opacity-5'
          } border border-border rounded cursor-pointer flex items-center justify-center py-1.5  px-5 flex-1`}
        >
          {option.icon && <span className='mr-2 flex-none'>{option.icon}</span>}
          {option.disabled ? (
            <div>
              <ToolTip title='Coming soon'>
                <Text
                  color='text-lightBlack'
                  size='sm'
                  weight='medium'
                  className='overflow-hidden whitespace-no-wrap truncate flex-none sm:text-xs'
                >
                  {option.title}
                </Text>
              </ToolTip>
            </div>
          ) : (
            <Text
              color='text-lightBlack'
              size='sm'
              weight='medium'
              className='overflow-hidden whitespace-no-wrap truncate flex-none'
            >
              {option.title}
            </Text>
          )}
        </div>
      ))}
    </div>
  );
};

export default observer(FilterTabs);
