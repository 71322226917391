import Avatar from '@/components/atoms/Avatar';
import Button from '@/components/atoms/Button';
import EmptyList from '@/components/atoms/EmptyList';
import Input from '@/components/atoms/Input';
import Modal from '@/components/atoms/modal';
import Spinner from '@/components/atoms/Spinner';
import Text from '@/components/atoms/Text';
import { DataTable } from '@/components/template/DataTable';
import { AuthStore } from '@/state/AuthenticationStore';
import { TeamMate } from '@/types/teammate.types';
import { createColumnHelper } from '@tanstack/react-table';
import { observer } from 'mobx-react-lite';
import { FC, useMemo, useState } from 'react';
import TableMenu from './TableMenu';

type TeammatesTableProps = {
  team_mates: TeamMate[];
  loading: boolean;
  deleteTeammate: (userId: string) => void;
};

const columnHelper = createColumnHelper<TeamMate>();

const TeammatesTable: FC<TeammatesTableProps> = ({
  team_mates,
  loading,
  deleteTeammate,
}) => {
  const [deleteModal, setDeleteModal] = useState(false);
  const [value, setValue] = useState('');
  const [selectedTeammate, setSelectedTeammate] = useState<TeamMate | null>();

  const workspaceCreatorId =
    AuthStore?.user_workspace_info?.active_workspace?.workspace?.created_by
      ?._id ?? '';

  const closeDeleteModal = () => {
    setDeleteModal(false);
  };

  const openDeleteModal = () => {
    setDeleteModal(true);
  };

  const handleShowDeleteModal = (team_mate: TeamMate) => {
    setSelectedTeammate(team_mate);
    openDeleteModal();
  };

  const teammateColumns = useMemo(
    () => [
      columnHelper.accessor('user', {
        id: 'name',
        cell: info => (
          <div className='flex items-center'>
            <Avatar alt={info.getValue()?.user_name} src='' size='md' />
            <div className='ml-2'>
              <div className='font-medium text-[14px] text-gray-900'>
                {info.getValue()?.user_name}
              </div>
              <div className='text-gray-500'>{info.getValue()?.email}</div>
            </div>
          </div>
        ),
        header: () => <span>Name</span>,
      }),
      columnHelper.accessor('status', {
        id: 'status',
        cell: info => {
          const user = info.row.original.user;
          return (
            <div className='relative'>
              <TableMenu user={user} status={info.getValue()} />
            </div>
          );
        },
        header: () => <span>Status</span>,
      }),
      columnHelper.accessor('teams', {
        id: 'teams',
        enableSorting: false,
        cell: info => <p>{info.getValue()}</p>,
        header: () => <span>Teams</span>,
      }),
      columnHelper.accessor('permission', {
        id: 'permission',
        enableSorting: false,
        cell: info => <p>{info.getValue()}</p>,
        header: () => <span>Permission</span>,
      }),
      // columnHelper.accessor('authentication', {
      //   id: 'authentication',
      //   enableSorting: false,
      //   cell: info => <p>{info.getValue()}</p>,
      //   header: () => <span>2fa</span>,
      // }),
      columnHelper.accessor('user._id', {
        id: 'actions',
        enableSorting: false,
        cell: info => (
          <div className='flex justify-end'>
            {info.getValue() !== workspaceCreatorId && (
              <Button
                size='sm'
                className='bg-warning hover:bg-warning/80'
                onClick={() => handleShowDeleteModal(info.row.original)}
                text='Delete'
              />
            )}
          </div>
        ),
        header: () => <span>Actions</span>,
      }),
    ],
    [columnHelper, team_mates],
  );

  return loading ? (
    <div className='h-full w-full flex flex-row justify-center items-center'>
      <Spinner size={40} color={'#033EB5'} />
    </div>
  ) : (
    <>
      <DataTable
        columns={teammateColumns as any}
        data={team_mates}
        headerClassName='bg-gray-100 text-lightBlack font-medium text-md first:w-[initial] py-2 even:w-auto last:w-auto'
        showPagination={team_mates.length > 0}
        noDataComponent={
          <EmptyList
            listName={'teammates'}
            title='There are no'
            subTitle='Add '
            height='60vh'
          />
        }
      />

      <Modal
        show={deleteModal}
        openModal={openDeleteModal}
        closeModal={closeDeleteModal}
        title='Delete Teammate'
        className='z-[10000] inline-block py-6 my-8 w-[100%] max-w-[440px] text-left align-center  transition-all transform bg-white shadow-xl rounded-[7px]'
      >
        <div className='px-4 mt-[10px]'>
          <Text size='sm' color='#656971'>
            You are about to delete this list. This action cannot be undone. if
            you are sure you want to do this, type DELETE in the box below
          </Text>
          <br />
          <Input
            placeholder='Enter DELETE to confirm'
            onChange={e => {
              const inputValue = e.target.value;
              setValue(inputValue);
            }}
          />
          <div className='mt-4 text-center flex justify-between space-x-4'>
            <div className='flex flex-1'>
              <Button
                size='sm'
                text='Cancel'
                variant='outline'
                onClick={() => {
                  closeDeleteModal();
                }}
                className='w-full'
              />
            </div>

            <div className='flex flex-1'>
              <Button
                size='sm'
                text='Delete'
                type='submit'
                className='bg-warning/90 hover:bg-warning/80 transition-colors w-full'
                disabled={value !== 'DELETE'}
                onClick={() => {
                  closeDeleteModal();
                  deleteTeammate(selectedTeammate?.user?._id as string);
                }}
              />
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};
export default observer(TeammatesTable);
