import Search from '@/components/atoms/Search';
import FilterTabs from '@/components/molecules/FilterTabs';
import { FC, useEffect, useState } from 'react';
import OrderList from './orderList';
import Dropdown from '@/pages/EmailMarketing/components/Dropdown';
import { User } from '@/types/user.types';
import { useContact } from '@/hooks/useContact';

interface OrdersProps {
  data: User | null;
}

const Orders: FC<OrdersProps> = ({ data }) => {
  const [activeTab, setActiveTab] = useState<number>(0);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const { getOrders } = useContact();

  const handleTabChange = (id: number) => {
    setActiveTab(id);
    if (data?._id) {
      if (id == 0) {
        getOrders(data._id);
      } else {
        getOrders(data?._id, { status: options[id].title.toUpperCase() });
      }
    }
  };
  const handleDropdownChange = (index: number) => {
    setActiveTab(index);
    if (data?._id) {
      if (index == 0) {
        getOrders(data._id);
      } else {
        getOrders(data?._id, { status: options[index].title.toUpperCase() });
      }
    }
  };
  const options = [
    { id: 0, title: 'All', icon: null },
    { id: 1, title: 'Pending', icon: null },
    { id: 2, title: 'Sold', icon: null },
    { id: 3, title: 'Cancelled', icon: null },
  ];

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
  return (
    <div className='flex flex-col py-4'>
      <div className='flex justify-between mb-4'>
        <div className='relative flex items-center mr-2 sm:w-96 md:w-[500px]'>
          <Search
            placeholder='Search an Order'
            transparent
            showCommand={false}
          />
        </div>

        {windowWidth > 450 ? (
          <FilterTabs
            options={options}
            onChange={handleTabChange}
            activeTab={activeTab}
          />
        ) : (
          <Dropdown
            options={options}
            onChange={handleDropdownChange}
            currentOption={options[activeTab].title}
            activeTab={activeTab}
          />
        )}
      </div>
      <OrderList />
    </div>
  );
};
export default Orders;
