import Button from '@/components/atoms/Button';
import Text from '@/components/atoms/Text';
import { useCustomField } from '@/hooks/useCustomField';
import { contactStore } from '@/state/ContactStore';
import { customFieldStore } from '@/state/CustomFieldStore';
import { Icon } from '@iconify/react';
import { observer } from 'mobx-react-lite';
import { useEffect, useRef, useState } from 'react';
// import UnsubscribeMenu from '../unsubscribemenu';
import { PHONE_NUMBER_REGEX } from '@/constants';
import { useContact } from '@/hooks/useContact';
import { useDebounce } from '@/hooks/useDebounce';
import { Link } from 'react-router-dom';
import EditDetails from './editDetails';

const UserDetails = ({ data }: any) => {
  const [visibleDetailsCount, setVisibleDetailsCount] = useState(4);
  const { customFields, customFieldsObject } = customFieldStore;
  const [isEdit, setIsEdit] = useState(false);
  const [viewMore, setViewMore] = useState(false);
  const [phone_number, set_phone] = useState<string | null>(
    data?.phone_number || null,
  );
  const [phoneFocus, setPhoneFocus] = useState<boolean>(false);
  const [isValid, setIsValid] = useState<boolean>(true);
  const [isNumAvailable, setIsNumAvailable] = useState<boolean>(true);
  const [buttonDisabled, setButtonDisabled] = useState<boolean>(false);
  const { getContactCustomFields, getCustomFields } = useCustomField();
  const loading = contactStore.updatingContact;
  const { checkPhoneNumberAvailabilty } = useContact();
  const [user_name, set_user_name] = useState<string | null>(
    data?.user_name || null,
  );
  const [email, set_email] = useState<string | null>(data?.email || null);

  const [country, set_country] = useState<string | null>(data?.country || null);
  const [city, set_city] = useState<string | null>(data?.city || null);
  const [status, set_status] = useState<string | null>(data?.status || null);
  const [company_name, set_company_name] = useState<string | null>(
    data?.company_name || null,
  );
  const [company_size, set_company_size] = useState<string | null>(
    data?.company_size || null,
  );
  const [company_website, set_company_website] = useState<string | null>(
    data?.company_website || null,
  );
  const [company_industry, set_company_industry] = useState<string | null>(
    data?.company_industry || null,
  );
  const focusedInputRef = useRef<HTMLInputElement | null>(null);
  const focusedSelectInputRef = useRef<HTMLSelectElement | null>(null);
  const { updateContact } = useContact();
  const { debounce } = useDebounce();

  // Function to toggle the visible details count
  const toggleVisibleDetails = () => {
    if (visibleDetailsCount === 4) {
      setVisibleDetailsCount(userDetails.length);
      setViewMore(true);
    } else {
      setVisibleDetailsCount(4);
      setViewMore(false);
    }
  };

  const handleEdit = (e: any) => {
    if (isEdit == false) {
      setIsEdit(true);
    } else {
      handleUpdateDetails();
      e?.preventDefault();
      document?.getElementById('SparkHubSaveEditedDetailsForContact')?.click();
    }
  };

  const isValidPhoneNumber = (phoneNumber: string) => {
    return PHONE_NUMBER_REGEX.test(phoneNumber);
  };

  const handlePhoneChange = (value: any) => {
    const payload = {
      id: data._id,
      number: value,
    };
    set_phone(value);
    setIsValid(isValidPhoneNumber(value));
    if (isValid) {
      debounce(() => handleCheckPhoneNumberAvailability(payload), 500);
    }
  };

  const handleCheckPhoneNumberAvailability = async (values: any) => {
    const isAvailable = await checkPhoneNumberAvailabilty(values);
    setIsNumAvailable(isAvailable);
  };

  const handleUpdateDetails = () => {
    const focusedInput = focusedInputRef.current;

    if (focusedInput) {
      const inputName = focusedInput.getAttribute('data-name');

      switch (inputName) {
        case 'user_name':
          set_user_name(focusedInput.value);
          break;
        case 'email':
          set_email(focusedInput.value);
          break;
        case 'phone_number':
          set_phone(focusedInput.value);
          break;
        case 'status':
          set_status(focusedSelectInputRef.current?.value ?? '');
          break;
        case 'country':
          set_country(focusedInput.value);
          break;
        case 'city':
          set_city(focusedInput.value);
          break;
        case 'company_name':
          set_company_name(focusedInput.value);
          break;
        case 'company_size':
          set_company_size(focusedInput.value);
          break;
        case 'company_website':
          set_company_website(focusedInput.value);
          break;
        case 'company_industry':
          set_company_industry(focusedInput.value);
          break;
        default:
          break;
      }
    }

    const update = {
      id: data._id,
      user: {
        user_name,
        email,
        phone_number: phone_number
          ? phone_number.includes('+')
            ? phone_number
            : '+' + phone_number
          : null,
        status,
        company_name,
        country,
        city,
        company_website,
        company_size,
        company_industry,
        // custom_fields: {
        //   ...customFieldsObject,
        // },
      },
    };

    updateContact(update);

    if (contactStore.contact == null && contactStore.updatingContact == false) {
      setIsEdit(true);
    } else {
      setIsEdit(false);
    }
  };
  useEffect(() => {
    if (!phone_number && phoneFocus) {
      setButtonDisabled(true);
    } else if (!phone_number && !phoneFocus) {
      setButtonDisabled(false);
    } else if (!isValid || !isNumAvailable) {
      setButtonDisabled(true);
    } else {
      setButtonDisabled(false);
    }
  }, [phone_number, phoneFocus, isValid, isNumAvailable]);

  const userData = {
    user_name,
    email,
    phone_number,
    status,
    company_name,
    country,
    city,
    company_website,
    company_size,
    company_industry,
    isValid,
    isNumAvailable,
    phoneFocus,
    focusedInputRef,
    focusedSelectInputRef,
    buttonDisabled,
    set_user_name,
    setPhoneFocus,
    set_email,
    set_country,
    set_city,
    set_status,
    set_company_name,
    set_company_size,
    set_company_website,
    set_company_industry,
    handlePhoneChange,
    handleUpdateDetails,
  };

  const userDetails = [
    {
      id: 1,
      name: 'Type',
      icon: (
        <Icon
          icon='ic:baseline-person'
          width={15}
          height={15}
          color='#898D94'
        />
      ),
      value: data?.contact_type || '-',
      visible: true,
    },
    {
      id: 2,
      name: 'Mail',
      icon: (
        <Icon icon='bi:mailbox2-flag' width={15} height={15} color='#898D94' />
      ),
      value: data?.email || '-',
      visible: true,
    },
    {
      id: 3,
      name: 'Phone',
      icon: (
        <Icon icon='raphael:iphone' width={15} height={15} color='#898D94' />
      ),
      value: data?.phone_number || '-',
      visible: true,
    },
    {
      id: 4,
      name: 'User ID',
      icon: (
        <Icon
          icon='mdi:user-badge-alert'
          width={15}
          height={15}
          color='#898D94'
        />
      ),
      value: data?._id?.slice(9) || '-',
      visible: true,
    },
    {
      id: 5,
      name: 'First seen',
      icon: <Icon icon='uit:calender' width={15} height={15} color='#898D94' />,
      value: new Date(data?.createdAt).toDateString() || '-',
      visible: false,
    },
    {
      id: 6,
      name: 'Last seen',
      icon: <Icon icon='uit:calender' width={15} height={15} color='#898D94' />,
      value: new Date(data?.last_seen).toDateString() || '-',

      visible: true,
    },
    {
      id: 7,
      name: 'Signed up',
      icon: <Icon icon='uit:calender' width={15} height={15} color='#898D94' />,
      value: new Date(data?.signup_date).toDateString() || '-',
      visible: false,
    },
    {
      id: 8,
      name: 'Country',
      icon: <Icon icon='gis:globe-o' width={15} height={15} color='#898D94' />,
      value: data?.country || '-',
      visible: false,
    },
    {
      id: 9,
      name: 'City',
      icon: (
        <Icon icon='healthicons:city' width={15} height={15} color='#898D94' />
      ),
      value: data?.city || '-',
      visible: false,
    },
    {
      id: 10,
      name: 'Company',
      icon: <Icon icon='mdi:company' width={15} height={15} color='#898D94' />,
      value: data?.company_name || '-',
      visible: false,
    },
    {
      id: 11,
      name: 'Size',
      icon: (
        <Icon
          icon='fluent:book-number-20-regular'
          width={15}
          height={15}
          color='#898D94'
        />
      ),
      value: data?.company_size || '-',
      visible: false,
    },
    {
      id: 12,
      name: 'Website',
      icon: (
        <Icon
          icon='fluent-mdl2:website'
          width={15}
          height={15}
          color='#898D94'
        />
      ),
      value: data?.company_website || '-',
      visible: false,
    },
    {
      id: 13,
      name: 'Industry',
      icon: (
        <Icon
          icon='streamline:industry-innovation-and-infrastructure'
          width={15}
          height={15}
          color='#898D94'
        />
      ),
      value: data?.company_industry || '-',
      visible: false,
    },
  ];

  useEffect(() => {
    getCustomFields();
    getContactCustomFields(data?._id);
  }, [data?._id]);

  return (
    <div className='flex flex-col'>
      <div className='flex gap-y-4'>
        <div className='flex justify-between items-center w-full my-2'>
          <Text size='md' color='text-black'>
            Contact Details
          </Text>
          <div className='flex space-x-4'>
            {isEdit == false ? (
              <Button
                text={'Edit'}
                variant='outline'
                loading={loading}
                onClick={handleEdit}
              />
            ) : (
              <Button
                text={'Done'}
                onClick={handleEdit}
                disabled={buttonDisabled}
              />
            )}
            {/* <UnsubscribeMenu /> */}
          </div>
        </div>
      </div>

      {isEdit ? (
        <EditDetails
          data={data}
          userData={userData}
          handlePhoneChange={handlePhoneChange}
        />
      ) : (
        <div className='mt-2'>
          {userDetails?.slice(0, visibleDetailsCount)?.map(item => (
            <div key={item.id} className='flex items-center mb-4'>
              <span className='mr-2'>{item.icon}</span>
              <p className='mr-2 basis-24 text-md text-gray-500'>{item.name}</p>
              <div
                className={`${
                  item.name == 'Mail' ||
                  item.name == 'User ID' ||
                  item.name == 'Website'
                    ? ''
                    : 'capitalize'
                } ${
                  item.name === 'Website' &&
                  data?.company_website !== null &&
                  data?.company_website !== '' &&
                  'text-secondary'
                } text-md text-gray-400 flex  truncate overflow-ellipsis`}
              >
                {/* Value of field */}
                {item.name !== 'Website' ? (
                  item.value && typeof item.value === 'string' ? (
                    item.value.toLowerCase()
                  ) : (
                    '-'
                  )
                ) : (
                  <Link
                    to={
                      data?.company_website?.startsWith('https://')
                        ? data?.company_website
                        : `https://${data?.company_website}`
                    }
                    target='_blank'
                    rel='noopener noreferrer'
                    className='whitespace-no-wrap truncate flex items-center justify-between hover:underline'
                  >
                    <Text size='sm' className='truncate'>
                      {data?.company_website?.slice(0, 45) || '-'}
                    </Text>
                  </Link>
                )}
              </div>
            </div>
          ))}
          {viewMore && (
            <>
              {customFields.length ? (
                customFields.map(customField => (
                  <div
                    className='flex items-center mb-4 '
                    key={customField?._id}
                  >
                    <span className='mr-2'>
                      <Icon
                        icon='tabler:placeholder'
                        width={15}
                        height={15}
                        color='#898D94'
                      />
                    </span>
                    <p className='mr-2 basis-24 text-md text-gray-500 whitespace-nowrap'>
                      {customField?.field}
                    </p>
                    {customField?.type === 'url' ? (
                      customFieldsObject[customField?._id] ? (
                        <Link
                          to={
                            customFieldsObject[customField?._id]?.startsWith(
                              'https://',
                            )
                              ? customFieldsObject[customField?._id]
                              : `https://${
                                  customFieldsObject[customField?._id]
                                }`
                          }
                          target='_blank'
                          rel='noopener noreferrer'
                          className='whitespace-no-wrap truncate flex items-center justify-between hover:underline'
                        >
                          <Text size='sm' className='truncate'>
                            {customFieldsObject[customField?._id]?.slice(0, 45)}
                          </Text>
                        </Link>
                      ) : (
                        <div className='text-md text-gray-400 capitalize'>
                          -
                        </div>
                      )
                    ) : (
                      <div
                        className={`text-md text-gray-400 capitalize overflow-hidden whitespace-nowrap truncate`}
                      >
                        {customFieldsObject[customField?._id] || '-'}
                      </div>
                    )}
                  </div>
                ))
              ) : (
                <p className='sr-only'></p>
              )}
            </>
          )}
        </div>
      )}
      <>
        {!isEdit && userDetails.length > 4 && (
          <Button
            text={visibleDetailsCount === 4 ? 'See more' : 'See less'}
            variant='outline'
            className='w-full h-[32px] mt-4'
            onClick={toggleVisibleDetails}
          />
        )}
      </>
    </div>
  );
};

export default observer(UserDetails);
