'use client';
import { useEffect, useState } from 'react';
import { ChevronUp, Check } from 'lucide-react';
import { Button } from '@/components/ui/button';
import { Card, CardContent, CardHeader, CardTitle } from '@/components/ui/card';
import { Badge } from '@/components/ui/badge';
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
} from '@/components/ui/dialog';
import { Input } from '@/components/ui/input';
import { Label } from '@/components/ui/label';
import { observer } from 'mobx-react-lite';
import { useNavigate, useOutletContext, useParams } from 'react-router-dom';
import { OutletContextType } from '../types';
import { replaceRouteParam } from '@/utils/routes';
import routeNames from '@/routes/routeNames';
import { AuthStore } from '@/state/AuthenticationStore';
import { contactStore } from '@/state/ContactStore';
import { emailMarketingStore } from '@/state/EmailMarketingStore';
import { useEmailMarketing } from '@/hooks/useEmailMarketing';

const CampaignReview = () => {
  const [isTestModalOpen, setIsTestModalOpen] = useState(false);
  const [testEmail, setTestEmail] = useState('');
  const navigate = useNavigate();
  const { channel } = useParams();
  const { saveAndSendCampaign } = useEmailMarketing();
  const handleSendTestEmail = (e: React.FormEvent) => {
    e.preventDefault();
    setIsTestModalOpen(false);
    setTestEmail('');
  };
  const { isNextClicked, setIsNextClicked, isSendClicked, setIsSendClicked } =
    useOutletContext<OutletContextType>();

  useEffect(() => {
    if (isNextClicked) {
      setIsNextClicked(false);
    }
  }, [isNextClicked]);

  useEffect(() => {
    if (isSendClicked) {
      sendCampaign();
      setIsSendClicked(false);
    }
  }, [isSendClicked]);

  const audience = () => {
    navigate(
      replaceRouteParam(
        `${routeNames.dashboard.emailMarketing}/campaign-flow/${channel}`,
        'workspaceId',
        AuthStore.user_workspace_info.active_workspace.workspace._id,
      ),
    );
  };

  const message = () => {
    navigate(
      replaceRouteParam(
        `${routeNames.dashboard.emailMarketing}/campaign-flow/${channel}/campaign-message`,
        'workspaceId',
        AuthStore.user_workspace_info.active_workspace.workspace._id,
      ),
    );
  };

  const sendCampaign = () => {
    saveAndSendCampaign({
      title: emailMarketingStore.campaignName,
      templateId: emailMarketingStore.template?._id,
      type: emailMarketingStore.type,
      draftDate: emailMarketingStore.draftDate,
      list: contactStore.campaignSenderList,
      description: emailMarketingStore.description,
      senderName: emailMarketingStore.emailParams.sender_name,
      senderEmail: emailMarketingStore.sender_email,
    });
  };

  return (
    <div className='min-h-screen bg-white pb-10'>
      {/* Main Content */}
      <div className='flex gap-6 p-6'>
        {/* Left Column */}
        <div className='flex-1 space-y-6'>
          {/* Email Section */}
          <Card>
            <CardHeader className='flex flex-row items-center justify-between p-6'>
              <div className='flex items-center gap-2'>
                <ChevronUp className='h-5 w-5' />
                <CardTitle>Email</CardTitle>
                <Badge
                  variant='secondary'
                  className='bg-green-100 text-green-800'
                >
                  <Check className='h-3 w-3 mr-1' />
                  Completed
                </Badge>
              </div>
            </CardHeader>
            <CardContent className='p-6 pt-0 space-y-6'>
              {/* Recipients */}
              <div className='space-y-4'>
                <div className='flex items-center justify-between'>
                  <h3 className='text-lg font-semibold'>Recipients</h3>
                  <Button onClick={audience} variant='outline' size='sm'>
                    Edit
                  </Button>
                </div>
                <div className='space-y-4'>
                  <div className='flex gap-2'>
                    <Check className='h-5 w-5 text-green-600' />
                    <div>
                      <h4 className='font-semibold'>
                        {contactStore.campaignSenderList.length} estimated
                        recipients
                      </h4>
                    </div>
                  </div>
                </div>
              </div>

              {/* Message */}
              <div className='space-y-4'>
                <div className='flex items-center justify-between'>
                  <h3 className='text-lg font-semibold'>Message</h3>
                  <Button onClick={message} variant='outline' size='sm'>
                    Edit
                  </Button>
                </div>
                <div className='space-y-4'>
                  <div className='flex gap-2'>
                    <Check className='h-5 w-5 text-green-600' />
                    <div>
                      <h4 className='font-semibold'>Subject line</h4>
                      <p className='text-md text-muted-foreground'>
                        {emailMarketingStore.emailParams.subject_line}
                      </p>
                    </div>
                  </div>
                  <div className='flex gap-2'>
                    <Check className='h-5 w-5 text-green-600' />
                    <div>
                      <h4 className='font-semibold'>Preview text</h4>
                      <p className='text-md text-muted-foreground'>
                        {emailMarketingStore.emailParams?.preview_text}
                      </p>
                    </div>
                  </div>
                  <div className='flex gap-2'>
                    <Check className='h-5 w-5 text-green-600' />
                    <div>
                      <h4 className='font-semibold'>Content</h4>
                      <p className='text-md text-muted-foreground'>
                        You created this variation with the drag and drop
                        editor.{' '}
                      </p>
                      <p className='text-md text-muted-foreground'>
                        The plain text version of this variation will be
                        automatically generated.{' '}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </CardContent>
          </Card>

          {/* Tracking Section */}
          <Card>
            <CardHeader className='flex flex-row items-center justify-between p-6'>
              <CardTitle>Tracking</CardTitle>
              <Button onClick={audience} variant='outline' size='sm'>
                Edit
              </Button>
            </CardHeader>
            <CardContent className='p-6 pt-0'>
              <div className='flex gap-2'>
                <Check className='h-5 w-5 text-green-600' />
                <div>
                  <h4 className='font-semibold'>Tracking</h4>
                  <p className='text-md text-muted-foreground'>
                    You have not enabled UTM parameters, which is additional
                    tracking to all links. This allows source tracking within
                    third-party reporting tools such as Google Analytics.
                  </p>
                </div>
              </div>
            </CardContent>
          </Card>
        </div>

        {/* Right Column - Preview */}
        <div className='w-[400px]'>
          <Card>
            <CardContent className='p-6'>
              <div className='flex items-center gap-2 mb-4'>
                <div className='p-2 rounded-full bg-gray-100'>
                  <svg className='h-5 w-5' viewBox='0 0 24 24'>
                    <path
                      fill='currentColor'
                      d='M12 4a4 4 0 014 4v2h2v10H6V10h2V8a4 4 0 014-4zm0 2a2 2 0 00-2 2v2h4V8a2 2 0 00-2-2z'
                    />
                  </svg>
                </div>
                <div>
                  <h3 className='font-semibold'>
                    {contactStore.campaignSenderList.length} total audience
                  </h3>
                </div>
              </div>
              <div className='border rounded-lg p-4'>
                <h4 className='font-semibold mb-2'>Email</h4>
                <div className='aspect-[9/16] bg-gray-100 rounded-lg'>
                  {emailMarketingStore.template ? (
                    <div
                      className={`h-full overflow-y-auto overflow-x-hidden rounded-lg`}
                      dangerouslySetInnerHTML={{
                        __html: emailMarketingStore.template.html as string,
                      }}
                    />
                  ) : (
                    <div
                      className={`h-full overflow-y-auto overflow-x-hidden rounded-lg`}
                      dangerouslySetInnerHTML={{
                        __html: emailMarketingStore.templateOnDemand
                          ?.html as string,
                      }}
                    />
                  )}
                </div>
              </div>
            </CardContent>
          </Card>
        </div>
      </div>
      <Dialog open={isTestModalOpen} onOpenChange={setIsTestModalOpen}>
        <DialogContent>
          <DialogHeader>
            <DialogTitle>Send Test Email</DialogTitle>
          </DialogHeader>
          <form onSubmit={handleSendTestEmail} className='space-y-4'>
            <div>
              <Label htmlFor='testEmail'>Recipient Email</Label>
              <Input
                id='testEmail'
                type='email'
                value={testEmail}
                onChange={e => setTestEmail(e.target.value)}
                placeholder='Enter email address'
                className='focus-visible:outline-none focus-visible:ring-0 focus-visible:ring-ring focus-visible:ring-offset-0 focus-within:border focus-within:border-secondary'
                required
              />
            </div>
            <Button
              className=' bg-black hover:bg-gray-800 text-white'
              type='submit'
            >
              Send Test
            </Button>
          </form>
        </DialogContent>
      </Dialog>
    </div>
  );
};
export default observer(CampaignReview);
