import MainChatUi from '@/components/template/MainChatUi';

const Email = () => {
  return (
    <>
      <MainChatUi conversationChannel='EMAIL' title='Email' />
    </>
  );
};
export default Email;
